import { useEffect, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ChatikContext } from '@hh.ru/chatik-integration';

import { useSelector } from 'src/hooks/useSelector';
import { responseStreakUpdate } from 'src/models/applicantResponseStreaks';
import { JobSearchStatusTrigger } from 'src/models/applicantUserStatuses';

import useDispatchAfterResponseNotification from 'src/components/VacancyView/useDispatchAfterResponseNotification';

export default () => {
    const dispatch = useDispatch();
    const chatikCtx = useContext(ChatikContext);
    const dispatchNotification = useDispatchAfterResponseNotification();

    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const showResponse = useSelector((state) => state.vacancyView.responseInfo?.showResponse);
    const topicId = useSelector((state) => state.vacancyView.responseInfo?.respondedWithTopicId);
    const applicantUserStatusesTrigger = useSelector((state) => state.applicantUserStatusesTrigger);
    const chatId = useSelector(
        (state) => state.applicantVacancyResponseStatuses[vacancyId]?.negotiations?.topicList?.[0]?.chatId
    );

    const openChatik = useCallback(
        () => chatikCtx?.openChatik?.({ chatId, hhtmFromLabel: 'response_notification' }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chatId]
    );

    useEffect(() => {
        if (showResponse) {
            dispatch(
                responseStreakUpdate({
                    vacancyId,
                    data: {
                        responsesCount: 1,
                        responsesRequired: 10,
                        vacancyId,
                    },
                })
            );

            dispatchNotification(applicantUserStatusesTrigger === JobSearchStatusTrigger.AfterVacancyResponse, {
                readPercent: null,
                topicId,
                vacancyId,
                openChatik,
            });
        }
    }, [applicantUserStatusesTrigger, dispatch, dispatchNotification, topicId, showResponse, vacancyId, openChatik]);
};
