import { Dispatch, FC, RefObject } from 'react';
import { AnyAction } from 'redux';

import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import VacancyEmployerActions from 'src/pages/VacancyView/components/VacancyEmployerActions';

interface VacancyHeaderProps {
    checkedVacancies: RefObject<Record<number, number[]>>;
    dispatchModal: Dispatch<AnyAction>;
}

const VacancyHeader: FC<VacancyHeaderProps> = ({ checkedVacancies, dispatchModal }) => {
    const availableVacancyActions = useSelector((state) => state.availableVacancyActions);
    const permissions = useSelector((state) => state.permissions);
    const userType = useSelector((state) => state.userType);
    const vacancyView = useSelector((state) => state.vacancyView);

    const hasAvailableActions =
        availableVacancyActions.update ||
        availableVacancyActions.edit ||
        availableVacancyActions.duplicate ||
        availableVacancyActions.archive ||
        availableVacancyActions.upgrade ||
        availableVacancyActions.delete ||
        availableVacancyActions.restore ||
        availableVacancyActions.hide ||
        availableVacancyActions.restoreToArchive ||
        permissions.includes('ModerateVacancy') ||
        permissions.includes('VacModerList') ||
        vacancyView.canViewResponses;

    if (!hasAvailableActions) {
        return null;
    }

    return (
        <div className="vacancy-header">
            <VacancyEmployerActions
                checkedVacancies={checkedVacancies}
                dispatchModal={dispatchModal}
                isBackoffice={userType === UserType.BackOffice}
            />
        </div>
    );
};

export default VacancyHeader;
