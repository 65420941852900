import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    success: 'admin.callTracking.vacancy.disable.notification.success',
    alreadyDisabled: 'admin.callTracking.vacancy.disable.notification.already.disabled',
};
const CallTrackingDisableSuccess: TranslatedComponent = ({ trls }) => {
    return <p>{trls[TrlKeys.success]}</p>;
};

export default {
    Element: translation(CallTrackingDisableSuccess),
    kind: 'ok',
    autoClose: true,
};

const CallTrackingAlreadyDisabled: TranslatedComponent = ({ trls }) => {
    return <p>{trls[TrlKeys.alreadyDisabled]}</p>;
};

export const callTrackingAlreadyDisabled = {
    Element: translation(CallTrackingAlreadyDisabled),
    kind: 'ok',
    autoClose: true,
};
