import { VacancyConversionStatistics, ComparisonResult } from 'src/models/vacancyConversionStatistics';

import { VacancyComparisonQuality } from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard/types';

const getComparisonQuality = (
    conversionComparison: VacancyConversionStatistics['viewToResponseConversionComparison']
): VacancyComparisonQuality => {
    if (!conversionComparison?.comparisonResult || !conversionComparison?.value) {
        return 'noCalc';
    }

    const { comparisonResult, value } = conversionComparison;

    if (
        (comparisonResult === ComparisonResult.HIGHER && value > 50) ||
        (comparisonResult === ComparisonResult.LOWER && value < 50)
    ) {
        return 'high';
    }

    return 'low';
};

export default getComparisonQuality;
