import classnames from 'classnames';

import { Avatar, Text, VSpacing } from '@hh.ru/magritte-ui';
import { InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { AnalyzeResultType } from 'src/models/canvacVacancySalaryData';

import styles from './no-found-data.less';

const TrlKeys = {
    specifiedRoleOther: {
        title: 'employer.vacancy.noFoundData.specifiedRoleOther.title',
        text1: 'employer.vacancy.noFoundData.specifiedRoleOther.text1',
        text2: 'employer.vacancy.noFoundData.specifiedRoleOther.text2',
    },
    commonError: {
        title: 'employer.vacancy.noFoundData.commonError.title',
        text: 'employer.vacancy.noFoundData.commonError.text',
    },
};

interface NoFoundDataProps {
    analyzeResultType?: AnalyzeResultType;
    vacancyId?: number;
}

const NoFoundData: TranslatedComponent<NoFoundDataProps> = ({ analyzeResultType, vacancyId, trls }) => {
    const noFoundDataCardRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_no_found_data',
        vacancyId,
        analyzeResultType,
    });

    const specifiedRoleOther = (
        <>
            <span className={styles.noFoundDataTextCenter}>
                <Text style="secondary">{trls[TrlKeys.specifiedRoleOther.title]}</Text>
            </span>
            <VSpacing default={48} xs={32} />
            <div className={styles.noFoundDataCol}>
                <div className={styles.noFoundDataText}>
                    <Text Element="span" style="contrast" typography="label-3-regular">
                        {trls[TrlKeys.specifiedRoleOther.text1]}
                    </Text>
                </div>
                <div className={styles.noFoundDataText}>
                    <Text Element="span" style="contrast" typography="label-3-regular">
                        {trls[TrlKeys.specifiedRoleOther.text2]}
                    </Text>
                </div>
            </div>
            <VSpacing default={24} xs={44} />
        </>
    );

    const commonError = (
        <span className={styles.noFoundDataTextCenter}>
            <Text Element="span" typography="subtitle-1-semibold">
                {trls[TrlKeys.commonError.title]}
            </Text>
            <VSpacing default={8} />
            <Text Element="span" style="secondary" typography="label-3-regular">
                {trls[TrlKeys.commonError.text]}
            </Text>
            <VSpacing default={24} />
        </span>
    );

    const imgCssClass =
        analyzeResultType === AnalyzeResultType.NonApplicableProfRoleOther
            ? styles.noFoundDataImgBird
            : styles.noFoundDataImgCat;

    return (
        <div className={classnames(styles.noFoundData, imgCssClass)} ref={noFoundDataCardRef}>
            <Avatar
                size={48}
                shapeCircle
                aria-label="No data"
                mode="icon"
                style="secondary"
                icon={<InfoCircleFilledSize24 initial="tertiary" />}
            />
            <VSpacing default={16} />
            {analyzeResultType === AnalyzeResultType.NonApplicableProfRoleOther ? specifiedRoleOther : commonError}
        </div>
    );
};

export default translation(NoFoundData);
