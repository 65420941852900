import { FC, ReactNode } from 'react';

import { Cell, CellText, CellTextProps } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface ManagerListItemProps {
    children: string;
    actions?: ReactNode;
    style?: CellTextProps['style'];
}

const ManagerListItem: FC<ManagerListItemProps> = ({ children, actions, style }) => (
    <div className={styles.item}>
        <Cell>
            <CellText maxLines={1} style={style}>
                {children}
            </CellText>
        </Cell>
        {!!actions && <div className={styles.actions}>{actions}</div>}
    </div>
);

export default ManagerListItem;
