export const chartData = {
    // рандомные значения, т.к. график декоративный
    LOW: [
        0.38, 0.34, 0.22, 0.2, 0.31, 0.42, 0.17, 0.36, 0.34, 0.38, 0.23, 0.34, 0.41, 0.27, 0.23, 0.3, 0.34, 0.36, 0.22,
        0.38, 0.34, 0.22, 0.2, 0.31, 0.42, 0.17, 0.36, 0.34, 0.38, 0.23,
    ],
    MEDIUM: [
        0.55, 0.72, 0.56, 0.54, 0.55, 0.42, 0.66, 0.7, 0.49, 0.38, 0.63, 0.48, 0.59, 0.44, 0.71, 0.44, 0.57, 0.48, 0.44,
        0.55, 0.72, 0.56, 0.54, 0.55, 0.42, 0.66, 0.7, 0.49, 0.38, 0.63,
    ],
    HIGH: [
        0.79, 0.88, 0.76, 0.84, 0.72, 0.59, 0.81, 0.7, 0.81, 0.7, 0.81, 0.76, 0.85, 0.66, 0.86, 0.76, 0.89, 0.76, 0.69,
        0.79, 0.88, 0.76, 0.84, 0.72, 0.59, 0.81, 0.7, 0.81, 0.7, 0.81,
    ],
};
