import { XAxis } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/types';

import { RectangleChartPoint } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/types';

const CIRCLE_ICON_RADIUS = 10;

export const getRectWidth = (point: RectangleChartPoint, xAxis: XAxis): number => {
    const width = xAxis(point.endDate) - xAxis(point.startDate);
    // в случае когда start === end выводим график размером с иконку
    return width || CIRCLE_ICON_RADIUS * 2;
};
