import { generatePath } from 'react-router';

import Analytics from '@hh.ru/analytics-js';
import { Button, Card, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';
import { CanvacSimilarVacancy } from 'src/models/canvacSimilarVacancies';
import { VacancyConversionStatistics } from 'src/models/vacancyConversionStatistics';

import CompetitorCardHeading from 'src/pages/VacancyView/components/VacancyStatTab/CompetitorCard/CompetitorCardHeading';
import SimilarVacancyList from 'src/pages/VacancyView/components/VacancyStatTab/CompetitorCard/SimilarVacancyList';

const TrlKeys = {
    link: 'employer.vacancy.tab.stat.salaryChart.competitorCard.link.text',
};

interface CompetitorCardProps {
    data: VacancyConversionStatistics;
    vacancies: CanvacSimilarVacancy[];
}

const CompetitorCard: TranslatedComponent<CompetitorCardProps> = ({ data, vacancies, trls }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);

    const competitorCardRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_competitor_card',
        vacancyId,
    });

    const onButtonClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_competitor_card_link', { vacancyId });
    };

    return (
        <Card stretched showBorder padding={12} borderRadius={24} ref={competitorCardRef}>
            <Card stretched padding={12}>
                <CompetitorCardHeading data={data} />
                <VSpacing default={24} xs={20} />
                <SimilarVacancyList vacancies={vacancies} vacancyId={vacancyId} />
            </Card>
            <VSpacing default={12} />
            {vacancyId && (
                <Button
                    stretched
                    Element={Link}
                    target="_blank"
                    to={generatePath(paths.vacancyEdit, { vacancyId })}
                    mode="secondary"
                    style="accent"
                    onClick={onButtonClick}
                    data-qa="tab-stat-btn-edit-vacancy"
                >
                    {trls[TrlKeys.link]}
                </Button>
            )}
        </Card>
    );
};

export default translation(CompetitorCard);
