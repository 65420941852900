import { FC, ReactElement } from 'react';

import { Card } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import styles from './vacancy-important-banners.less';

interface BannerWrapperProps {
    children: ReactElement;
    dataQa?: string;
    onClick?: VoidFunction;
}

const BannerWrapper: FC<BannerWrapperProps> = ({ dataQa, onClick, children }) => {
    return (
        <Card borderRadius={12} padding={12} stretched actionCard showShadowOnHover data-qa={dataQa} onClick={onClick}>
            <div className={styles.banner}>
                <div className={styles.bannerContent}>{children}</div>
                <div className={styles.bannerIcon}>
                    <ChevronRightOutlinedSize16 initial="tertiary" />
                </div>
            </div>
        </Card>
    );
};

export default BannerWrapper;
