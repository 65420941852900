import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Action, Button, TooltipHover, useBreakpoint } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyShareModal, { VacancyShareModalProps } from 'src/components/HiringManager/VacancyShareModal';
import { getIsManagerMCP } from 'src/components/HiringManager/utils/getIsManagerMCP';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';
import { ShortHiringManagerData, vacancyAddHiringManagersAction } from 'src/models/vacancyView';

const TrlKeys = {
    triggerText: 'abstract.add',
    tooltip: 'employer.vacancy.managers.addHiringManager.modal.trigger.tooltip',
};

interface AddHiringManagerActionProps {
    type: 'button' | 'action';
}

const AddHiringManagerAction: TranslatedComponent<AddHiringManagerActionProps> = ({ trls, type }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const activatorRef = useRef(null);
    const vacancyName = useSelector((state) => state.vacancyView.name);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId?.toString());
    const [isOpenModal, openModal, closeModal] = useOnOffState(false);

    const handleUpdateHiringManagers = useCallback<NonNullable<VacancyShareModalProps['onSuccessSubmit']>>(
        (selectedManagers, selectedPermissions) => {
            const allPermissions = Object.values(VacancyPermission);
            const hiringManagers: ShortHiringManagerData[] = selectedManagers.map((manager) => {
                const withMcpPermission = getIsManagerMCP(manager);
                return {
                    id: manager.id.toString(),
                    fullName: manager.fullName,
                    withMcpPermission,
                    permissions: withMcpPermission ? allPermissions : selectedPermissions,
                };
            });

            dispatch(vacancyAddHiringManagersAction(hiringManagers));
        },
        [dispatch]
    );

    if (!vacancyId) {
        return null;
    }

    return (
        <>
            {type === 'button' && (
                <Button
                    mode="tertiary"
                    size="small"
                    icon={<PlusOutlinedSize16 />}
                    hideLabel={isMobile}
                    aria-label={isMobile ? trls[TrlKeys.triggerText] : ''}
                    onClick={openModal}
                >
                    {trls[TrlKeys.triggerText]}
                </Button>
            )}
            {type === 'action' && <Action ref={activatorRef} icon={PlusOutlinedSize16} onClick={openModal} />}
            <TooltipHover placement={'top-center'} activatorRef={activatorRef}>
                {trls[TrlKeys.tooltip]}
            </TooltipHover>
            <VacancyShareModal
                kind="addHiringManager"
                isVisible={isOpenModal}
                vacanciesIds={[vacancyId]}
                vacanciesNames={[vacancyName]}
                handleCloseModal={closeModal}
                onSuccessSubmit={handleUpdateHiringManagers}
            />
        </>
    );
};

export default translation(AddHiringManagerAction);
