import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { RELATED_VACANCIES_TYPES, RelatedVacanciesType } from 'src/components/RelatedVacancies/relatedVacanciesTypes';

const TrlKeys = {
    titleSimilar: 'vacancy.similarVacancy.title',
    titleSuitable: 'vacancy.suitable.title',
    titleMaybeSuitable: 'vacancy.maybeSuitable.title',
};

interface RelatedVacanciesTitleProps {
    type?: RelatedVacanciesType;
    isMagritteVacancy?: boolean;
}

const RelatedVacanciesTitle: TranslatedComponent<RelatedVacanciesTitleProps> = ({ trls, type, isMagritteVacancy }) => {
    if (type === RELATED_VACANCIES_TYPES.SUITABLE) {
        return <>{isMagritteVacancy ? trls[TrlKeys.titleMaybeSuitable] : trls[TrlKeys.titleSuitable]}</>;
    }

    return <>{trls[TrlKeys.titleSimilar]}</>;
};

export default translation(RelatedVacanciesTitle);
