import { FC } from 'react';

import { Button } from '@hh.ru/magritte-ui';

import { ActionButtonProps } from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/types';

const UseResumeSearchButton: FC<ActionButtonProps> = ({ vacancyId, isXS, children, sendEventClick }) => {
    const onButtonClick = () => {
        sendEventClick('vacancy_view_stat_tab_advice_use_resume_search');
    };

    return (
        <Button
            Element="a"
            href={`/search/resume?vacancy_id=${vacancyId}&order_by=relevance&search_period=365`}
            target={'_blank'}
            mode="primary"
            style="accent"
            size={isXS ? 'small' : 'medium'}
            onClick={onButtonClick}
        >
            {children}
        </Button>
    );
};

export default UseResumeSearchButton;
