import { FC } from 'react';
import classnames from 'classnames';

import { CompetitionLevel } from 'src/models/canvacVacancySalaryData';

import styles from './competition-chart.less';

interface BarChartProps {
    data: number[];
    level: CompetitionLevel;
    onChartClick: () => void;
}

const BAR_HEIGHT = 128;

const CompetitionChart: FC<BarChartProps> = ({ data, level, onChartClick }) => {
    return (
        <div className={styles.barChartContainer} onClick={onChartClick}>
            {data.map((value, idx) => (
                <div className={styles.bar} key={idx}>
                    <div
                        className={classnames(styles.barValue, styles[level.toLowerCase()])}
                        style={{ height: value * BAR_HEIGHT }}
                    />
                </div>
            ))}
        </div>
    );
};

export default CompetitionChart;
