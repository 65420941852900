import { useState, useCallback, useEffect, useRef, Fragment, RefObject } from 'react';
import { useDispatch } from 'react-redux';

import { TranslatedComponent } from '@hh.ru/front-static-app';
import Button, { ButtonScale, ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import { H1Section, H2 } from 'bloko/blocks/header';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import TranslateGuard from 'bloko/blocks/translateGuard';
import VSpacing from 'bloko/blocks/vSpacing';
import Metrics from 'bloko/common/metrics';
import throttle from 'bloko/common/throttle';
import { TypeElementMetrics } from 'bloko/common/types';

import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';
import VacancySerpBanner from 'src/components/VacancySerpBanner';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { pushRelatedVacanciesAction } from 'src/models/relatedVacancies';
import { addUserLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import fetcher from 'src/utils/fetcher';

import RelatedVacanciesTitle from 'src/components/RelatedVacancies/Title';
import { RelatedVacanciesType } from 'src/components/RelatedVacancies/relatedVacanciesTypes';

const AUTO_LOAD_PAGES = 3;
const SCROLL_THROTTLE_DELAY_MS = 1000;
const SCROLL_BOTTOM_OFFSET = 500;
const BANNER_POSITION = 3;

const TrlKeys = {
    loadMoreVacancies: 'vacancy.related.loadMoreVacancies',
};

function isScrolledPastThreshold(currentPageNumber: number, totalPages: number, wrapper: TypeElementMetrics) {
    const viewportBottom = Metrics.getViewportMetrics().bottom;
    const contentBottom = Metrics.getRelativeMetrics(wrapper).bottom;
    return (
        viewportBottom + SCROLL_BOTTOM_OFFSET >= contentBottom &&
        currentPageNumber < totalPages &&
        currentPageNumber < AUTO_LOAD_PAGES
    );
}

interface RelatedVacanciesProps {
    blockRef?: RefObject<HTMLDivElement>;
    initialType?: RelatedVacanciesType;
    isEmployerRedesign?: boolean;
}

const RelatedVacancies: TranslatedComponent<RelatedVacanciesProps> = ({
    blockRef,
    initialType,
    isEmployerRedesign,
    trls,
}) => {
    const relatedVacancies = useSelector((state) => state.relatedVacancies);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const searchSessionId = useSelector((state) => state.searchSessionId);
    const bannersBatchUrl = useSelector((state) => state.bannersBatchUrl);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const currentPageNumber = useRef(1);
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    const loadMoreVacancies = useCallback(() => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        fetcher
            .get('/shards/vacancy/related_vacancies', {
                params: {
                    vacancyId,
                    page: currentPageNumber.current,
                    searchSessionId,
                },
            })
            .then((response) => {
                const actions = response.vacancies.map(({ vacancyId, userLabels }) =>
                    addUserLabelsForVacancies({ vacancyId, labels: userLabels })
                );

                dispatch([...actions, pushRelatedVacanciesAction(response.vacancies)]);
                currentPageNumber.current += 1;
                if (relatedVacancies.totalPages && currentPageNumber.current >= relatedVacancies.totalPages) {
                    setShowLoadMore(false);
                } else if (currentPageNumber.current >= AUTO_LOAD_PAGES) {
                    setShowLoadMore(true);
                }
            })
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, isLoading, relatedVacancies, searchSessionId, vacancyId]);

    useEffect(() => {
        const relatedVacanciesTotalPages = relatedVacancies.totalPages;
        if (!relatedVacancies.resultsFound || !relatedVacanciesTotalPages) {
            return undefined;
        }
        const onScrollHandler = throttle(() => {
            if (isLoading || !wrapperRef.current) {
                return;
            }
            if (isScrolledPastThreshold(currentPageNumber.current, relatedVacanciesTotalPages, wrapperRef.current)) {
                loadMoreVacancies();
            }
        }, SCROLL_THROTTLE_DELAY_MS);

        document.addEventListener('scroll', onScrollHandler);

        return () => {
            document.removeEventListener('scroll', onScrollHandler);
        };
    }, [isLoading, relatedVacancies, loadMoreVacancies, currentPageNumber, wrapperRef]);

    if (!relatedVacancies.resultsFound) {
        return null;
    }

    const renderBannerIfNeeded = (index: number) => {
        const relatedVacanciesItemsOnPages = relatedVacancies.itemsOnPage;
        if (
            !bannersBatchUrl ||
            !relatedVacanciesItemsOnPages ||
            index % relatedVacanciesItemsOnPages !== BANNER_POSITION
        ) {
            return null;
        }
        const isOdd = Math.floor((index - BANNER_POSITION) / relatedVacanciesItemsOnPages) % 2 !== 0;
        const bannerNumberOnPage = Math.ceil(index / (BANNER_POSITION * 2));
        return (
            <VacancySerpBanner
                isOdd={isOdd}
                position={index}
                adsPositionInfo={`${bannerNumberOnPage}_${index}`}
                bannerNum={bannerNumberOnPage}
            />
        );
    };
    const relatedVacanciesTitleType = initialType || relatedVacancies.type;

    return (
        <div className="vacancy-section" ref={blockRef} data-qa="vacancy-view-vacancies-from-search">
            {isEmployerRedesign ? (
                <H2>
                    <RelatedVacanciesTitle type={relatedVacanciesTitleType} />
                </H2>
            ) : (
                <H1Section Element="h2">
                    <RelatedVacanciesTitle type={relatedVacanciesTitleType} />
                </H1Section>
            )}
            <VSpacing base={isEmployerRedesign ? 6 : 4} />
            <div ref={wrapperRef}>
                {relatedVacancies?.vacancies?.map((vacancy, index) => (
                    <Fragment key={vacancy.vacancyId}>
                        {renderBannerIfNeeded(index)}
                        <VacancySearchItem
                            vacancy={vacancy}
                            vacancySource={Source.RelatedVacancies}
                            hhtmFromLabel={`${relatedVacancies.type}_vacancies`}
                        />
                    </Fragment>
                ))}
            </div>
            {isLoading && (
                <div className="related-vacancies-loading-indicator">
                    <Gap top>
                        <Loading scale={LoadingScale.Small} />
                    </Gap>
                </div>
            )}
            {!isLoading && showLoadMore && (
                <Gap top>
                    <Button
                        scale={ButtonScale.Small}
                        kind={ButtonKind.Secondary}
                        appearance={ButtonAppearance.Outlined}
                        onClick={loadMoreVacancies}
                    >
                        <TranslateGuard useSpan>{trls[TrlKeys.loadMoreVacancies]}</TranslateGuard>
                    </Button>
                </Gap>
            )}
        </div>
    );
};

export default translation(RelatedVacancies);
