import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import vacancyViewsCount from 'src/pages/VacancyView/components/VacancyViewsCountNotification';

const vacancyViewsCountAction = makeSetStoreField('vacancyViewsCount');
const useVacancyViewsCountNotification = () => {
    const vacancy = useSelector(({ vacancyView }) => vacancyView);
    const { addNotification } = useNotification();
    const abortController = useRef(null);
    const dispatch = useDispatch();
    const vacancyId = vacancy.vacancyId;
    const responseStatus = useSelector(
        ({ applicantVacancyResponseStatuses }) => applicantVacancyResponseStatuses[vacancyId]
    );

    const showNotification =
        responseStatus?.usedResumeIds?.length === 0 && !vacancy?.showResumeForm && !vacancy?.responseInfo?.doResponse;

    useEffect(() => {
        if (showNotification) {
            abortController.current = new AbortController();
            fetcher
                .get('/shards/vacancy_view_count', {
                    params: { vacancyId },
                    signal: abortController.current.signal,
                })
                .then((data) => {
                    if (data?.vacancyViewCount) {
                        dispatch(vacancyViewsCountAction(data?.vacancyViewCount));
                        addNotification(vacancyViewsCount);
                    }
                })
                .catch((e) => {
                    if (fetcher.isCancel(e)) {
                        return;
                    }
                    console.error(e);
                });
        }

        return () => {
            abortController.current?.abort();
        };
    }, [addNotification, dispatch, showNotification, vacancyId]);
};

export default useVacancyViewsCountNotification;
