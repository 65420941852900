import React, { useEffect, useRef, useContext, FC } from 'react';
import { select } from 'd3-selection';

import ChartContext from 'src/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/ChartContext';

import {
    RECTANGLE_CHART_HEIGHT,
    CIRCLE_ICON_RADIUS,
    BLOKO_ICON_WIDTH,
} from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/constants';
import { RectangleChartIconPoint } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/types';

interface RectangleChartIconProps {
    data: RectangleChartIconPoint[];
    withIcon?: boolean;
}

const RectangleChartIcon: FC<RectangleChartIconProps> = ({ data }) => {
    const { xAxis } = useContext(ChartContext).chartData;
    const { width: svgContainerWidth, height: svgContainerHeight } = useContext(ChartContext);

    const gContainerRef = useRef<SVGGElement | null>(null);

    // circle рисуется на графике в центре
    const yCircle = svgContainerHeight - RECTANGLE_CHART_HEIGHT / 2;
    // иконка рисуется в центре circle
    const yIcon = yCircle - BLOKO_ICON_WIDTH / 2;

    useEffect(() => {
        if (!gContainerRef.current) {
            return;
        }

        const chart = select(gContainerRef.current).selectAll('circle').data(data).enter();
        chart
            .append('circle')
            .attr('cx', (d) => xAxis(d.startDate))
            .attr('fill', (d) => d.color)
            .attr('cy', yCircle)
            .attr('r', CIRCLE_ICON_RADIUS);

        chart
            .append('g')
            .attr('transform', (d) => `translate(${xAxis(d.startDate) - BLOKO_ICON_WIDTH / 2}, ${yIcon})`)
            .html((d) => d.icon);
        // eslint-disable-next-line consistent-return
        return () => {
            ['circle', 'g'].forEach((el) => chart.selectAll(el).remove());
        };
    }, [data, xAxis, svgContainerWidth, yCircle, yIcon]);

    return <g ref={gContainerRef} />;
};

export default RectangleChartIcon;
