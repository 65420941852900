import { FC } from 'react';

import { Title } from '@hh.ru/magritte-ui';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { useSelector } from 'src/hooks/useSelector';

const VacancyTitleRedesigned: FC = () => {
    const { name, status } = useSelector((state) => state.vacancyView);

    return (
        <Title
            size="large"
            data-qa="vacancy-title"
            Element="h1"
            maxLines={2}
            style={status.archived || status.disabled ? 'secondary' : 'primary'}
        >
            <AsyncHighlighter>{name}</AsyncHighlighter>
        </Title>
    );
};

export default VacancyTitleRedesigned;
