import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Tabs, Tab, TabId, Divider, TooltipHover } from '@hh.ru/magritte-ui';
import { useReplace } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { setVacancyViewActiveTab, VacancyViewTab } from 'src/models/vacancyViewTab';
import TooltipOnboarding from 'src/pages/VacancyView/components/VacancyStatTab/TooltipOnboarding';

const TrlKeys = {
    tab: {
        [VacancyViewTab.Main]: 'employer.vacancy.tab.main',
        [VacancyViewTab.Stat]: 'employer.vacancy.tab.stat',
        [VacancyViewTab.History]: 'employer.vacancy.tab.history',
        [VacancyViewTab.Managers]: 'employer.vacancy.tab.managers',
    },
    tabManagersHover: 'employer.vacancy.tabManagersHover',
    newStatTab: 'employer.vacancy.tab.analytics',
};

const VacancyTabs: TranslatedComponent = ({ trls }) => {
    const managersTabRef = useRef<HTMLSpanElement>(null);
    const statTabRef = useRef<HTMLSpanElement>(null);
    const dispatch = useDispatch();
    const replace = useReplace();
    const { active: activeTab, tabs } = useSelector((state) => state.vacancyViewTab);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const isHHRu = useIsHhru();
    const isVacancyTabCompetitorsExp = useExperiment('vacancy_tab_competitors', isHHRu);

    const onChangeTab = (tab: TabId) => {
        const newLocation = urlParser(window.location.pathname + window.location.search);

        if (tab === VacancyViewTab.Main) {
            delete newLocation.params.activeTab;
        } else {
            newLocation.params.activeTab = tab;
        }
        replace(newLocation.href, { tab }, { cancelFetchingData: true });
        dispatch(
            setVacancyViewActiveTab({
                active: tab as VacancyViewTab,
            })
        );
        const analyticsParams = tab === VacancyViewTab.Stat && isHHRu ? { isVacancyTabCompetitorsExp } : {};
        Analytics.sendHHEventButtonClick(`vacancy_view_${tab}_tab_select`, {
            vacancyId,
            ...analyticsParams,
        });
    };

    return (
        <>
            <Tabs activeItemId={activeTab} mode="secondary" onChange={onChangeTab}>
                <Tab id={VacancyViewTab.Main} data-qa="tab-main">
                    <span>{trls[TrlKeys.tab.main]}</span>
                </Tab>
                <Tab id={VacancyViewTab.Stat} hidden={!tabs.includes(VacancyViewTab.Stat)} data-qa="tab-stat">
                    <TooltipOnboarding activatorRef={statTabRef} />
                    <span ref={statTabRef}>
                        {trls[isVacancyTabCompetitorsExp ? TrlKeys.newStatTab : TrlKeys.tab.stat]}
                    </span>
                </Tab>
                <Tab id={VacancyViewTab.History} hidden={!tabs.includes(VacancyViewTab.History)} data-qa="tab-history">
                    <span>{trls[TrlKeys.tab.history]}</span>
                </Tab>
                <Tab
                    id={VacancyViewTab.Managers}
                    hidden={!tabs.includes(VacancyViewTab.Managers)}
                    data-qa="tab-managers"
                >
                    <span ref={managersTabRef}>{trls[TrlKeys.tab.managers]}</span>
                    <TooltipHover placement="bottom-center" activatorRef={managersTabRef}>
                        {trls[TrlKeys.tabManagersHover]}
                    </TooltipHover>
                </Tab>
            </Tabs>
            <Divider />
        </>
    );
};

export default translation(VacancyTabs);
