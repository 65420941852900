import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyCompensation from 'src/components/VacancyView/VacancyCompensation';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ExperienceOption } from 'src/models/search/searchCriteria.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import KeyInfoItem from 'src/components/VacancyView/VacancyKeyInfo/KeyInfoItem';

import styles from './vacancy-key-info.less';

const TrlKeys = {
    experienceTitle: 'employer.vacancy.keyInfo.experience',
    experience: {
        [ExperienceOption.NoExperience]: 'workExperience.noExperience',
        [ExperienceOption.Between1And3]: 'workExperience.between1And3',
        [ExperienceOption.Between3And6]: 'workExperience.between3And6',
        [ExperienceOption.MoreThan6]: 'workExperience.moreThan6',
    },
};

const VacancyKeyInfo: TranslatedComponent = ({ trls }) => {
    const vacancyView = useSelector(({ vacancyView }) => vacancyView);
    const city = vacancyView?.area?.name;
    const workExperience = vacancyView?.workExperience;
    const vacancyCode = vacancyView?.['@vacancyCode'];

    return (
        <Text typography="label-2-regular">
            <div className={styles.vacancyKeyInfo}>
                <KeyInfoItem first>{city}</KeyInfoItem>
                {workExperience && (
                    <KeyInfoItem>
                        {trls[TrlKeys.experienceTitle]}
                        {NON_BREAKING_SPACE}
                        {trls[TrlKeys.experience[workExperience]]}
                    </KeyInfoItem>
                )}
                <KeyInfoItem>
                    <VacancyCompensation inHeader />
                </KeyInfoItem>
                {vacancyCode && (
                    <KeyInfoItem dataQa="vacancy-info-code" setMaxLength>
                        {vacancyCode}
                    </KeyInfoItem>
                )}
            </div>
        </Text>
    );
};

export default translation(VacancyKeyInfo);
