import classNames from 'classnames';

import { Conversion, TranslatedComponent } from '@hh.ru/front-static-app';
import { Text, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE, PLUS } from 'src/utils/constants/symbols';

import styles from './competition-stat-card.less';

const TrlKeys = {
    vacancyCount: 'employer.vacancy.tab.stat.competition.vacancy.count',
    more: 'employer.vacancy.tab.stat.competition.vacancy.more',
    applicants: {
        one: 'employer.vacancy.tab.stat.competition.applicants.one',
        some: 'employer.vacancy.tab.stat.competition.applicants.some',
        many: 'employer.vacancy.tab.stat.competition.applicants.many',
    },
    perVacancy: 'employer.vacancy.tab.stat.competition.per.vacancy',
};

interface CompetitionStatCardProps {
    numberOfSimilarVacancies: number;
    applicantsPerVacancy: number;
    ignoreXS: boolean;
}

const CompetitionStatCard: TranslatedComponent<CompetitionStatCardProps> = ({
    applicantsPerVacancy,
    numberOfSimilarVacancies,
    ignoreXS,
    trls,
}) => {
    const { isXS, isGtM } = useBreakpoint();
    const vacancyMaxCount = 300;
    const applicantsPerVacancyCount = Math.round(applicantsPerVacancy);

    return (
        <div
            className={classNames(styles.competitionStats, {
                [styles.mobile]: isXS && !ignoreXS,
                [styles.wideDesktop]: isGtM,
            })}
        >
            <Text typography="label-2-regular" data-qa="tab-stat-num-similar-vacancies">
                {numberOfSimilarVacancies === vacancyMaxCount ? `${vacancyMaxCount}${PLUS}` : numberOfSimilarVacancies}
            </Text>
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys.vacancyCount]}
            </Text>
            <Text typography="label-2-regular" data-qa="tab-stat-applicants-per-vacancy">
                {applicantsPerVacancyCount}
            </Text>
            <Text typography="label-3-regular" style="secondary">
                <Conversion
                    value={applicantsPerVacancyCount}
                    hasValue={false}
                    one={trls[TrlKeys.applicants.one]}
                    some={trls[TrlKeys.applicants.some]}
                    many={trls[TrlKeys.applicants.many]}
                    zero={trls[TrlKeys.applicants.many]}
                />
                {NON_BREAKING_SPACE}
                {trls[TrlKeys.perVacancy]}
            </Text>
        </div>
    );
};

export default translation(CompetitionStatCard);
