import { FC, useCallback } from 'react';
import { AnyAction, Dispatch } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { LinkOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import { useNotification } from 'src/components/Notifications/Provider';
import { ADD_MODAL, MODAL_VIDEOINTERVIEW } from 'src/components/VacancyModals/constants';
import VideoInterviewInfo from 'src/components/VacancyView/VideoInterviewInfo';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';

const TrlKeys = {
    videointerview: 'employer.myVacancyes.videointerview',
};

interface VideoInterviewActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    onClose?: VoidFunction;
    dispatchModal: Dispatch<AnyAction>;
    vacanciesIds: number[];
    showInfo?: boolean;
    labelEntry?: string;
}

const VideoInterviewAction: TranslatedComponent<VideoInterviewActionProps> = ({
    Component,
    labelEntry,
    triggerType,
    onClose,
    vacanciesIds,
    dispatchModal,
    trls,
    showInfo,
    additionalAnalyticsParams,
}) => {
    const triggerName = 'videointerview';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <LinkOutlinedSize24 />,
    });

    const { addNotification } = useNotification();
    const openModal = useCallback(async () => {
        onClose?.();
        let authToken;
        try {
            authToken = (await fetcher.get('/shards/employer/video_interview/auth')).token;
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        }
        Analytics.sendHHEventButtonClick('videoInterviewTrigger', {
            goal: 'PORTFOLIO-10079',
            labelEntry,
        });
        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType: MODAL_VIDEOINTERVIEW,
                data: {
                    vacanciesIds,
                    authToken,
                },
            },
        });
    }, [onClose, labelEntry, dispatchModal, vacanciesIds, addNotification]);

    return (
        <VideoInterviewInfo showInfo={showInfo}>
            <Component
                isPermitted
                triggerName={triggerName}
                triggerType={triggerType}
                onTrigger={openModal}
                batchLength={vacanciesIds.length}
                additionalAnalyticsParams={additionalAnalyticsParams}
                {...iconProps}
            >
                {trls[TrlKeys.videointerview]}
            </Component>
        </VideoInterviewInfo>
    );
};

export default translation(VideoInterviewAction);
