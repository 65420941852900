import { FC, useEffect, useCallback } from 'react';

import { useNotification } from 'src/components/Notifications/Provider';
import useRunEmployerVacancyAction from 'src/hooks/useRunEmployerVacancyAction';
import { useSelector } from 'src/hooks/useSelector';

import SnackbarRisePageNotification from 'src/components/VacancyActions/SnackbarRisePageNotification';

interface SnackbarRisePageProps {
    isRisePage?: boolean;
    triggerName?: string;
}

const SnackbarRisePage: FC<SnackbarRisePageProps> = ({ isRisePage, triggerName = '' }) => {
    const error = useSelector((state) => state.employerVacanciesRise?.error);
    const success = useSelector((state) => state.employerVacanciesRise?.success);
    const isSnackMessageForRisePage = useSelector((state) => state.employerVacanciesRise?.isSnackMessageForRisePage);
    const withoutStateMessages = !success && !error;
    const isMessageForRisePageButAnotherPage = isSnackMessageForRisePage && !isRisePage;
    const isRisePageButMessageForAnother = !isSnackMessageForRisePage && isRisePage;
    const { addNotification } = useNotification();
    const showNotification = useCallback(
        (triggerName?: string) => addNotification(SnackbarRisePageNotification, { props: { isRisePage, triggerName } }),
        [addNotification, isRisePage]
    );

    useEffect(() => {
        if (withoutStateMessages || isMessageForRisePageButAnotherPage || isRisePageButMessageForAnother) {
            return;
        }
        showNotification();
    }, [showNotification, withoutStateMessages, isMessageForRisePageButAnotherPage, isRisePageButMessageForAnother]);

    useRunEmployerVacancyAction(triggerName, () => showNotification(triggerName));

    return null;
};

export default SnackbarRisePage;
