import { PrinterOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';

const TrlKeys = {
    print: 'vacancy.print',
};

interface PrintActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: React.FC<BasicTriggerProps>;
    vacancyId?: number;
    draftId?: number;
    buttonsContainerRef: React.RefObject<HTMLInputElement>;
}

const PrintAction: TranslatedComponent<PrintActionProps> = ({ Component, triggerType, trls, vacancyId, draftId }) => {
    let triggerHref = '';
    if (vacancyId) {
        triggerHref = `/vacancy/view?id=${vacancyId}&print=true`;
    }
    if (draftId) {
        triggerHref = `/vacancy/view?draftId=${draftId}&print=true`;
    }

    return (
        <Component
            triggerName="print"
            triggerType={triggerType}
            triggerHref={triggerHref}
            isPermitted
            icon={<PrinterOutlinedSize24 />}
        >
            {(triggerType === 'menu' || triggerType === 'actionList') && trls[TrlKeys.print]}
        </Component>
    );
};

export default translation(PrintAction);
