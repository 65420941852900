import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { Link as MagritteLink, VSpacing, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AuctionBanner from 'src/components/Auction/Banner';
import useAuctionCreationEnabled from 'src/components/Auction/hooks/useAuctionCreationEnabled';
import useAuctionEditingEnabled from 'src/components/Auction/hooks/useAuctionEditingEnabled';
import SuitableVacanciesBanner from 'src/components/SuitableVacanciesBanner';
import VacancyImportantBanners from 'src/components/VacancyImportantBanners';
import VacancySuitableResumesBanner from 'src/components/VacancySuitableResumesBanner';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import AuctionStatistic from 'src/pages/VacancyView/components/AuctionStatistic';
import DisableCallTracking from 'src/pages/VacancyView/components/DisableCallTracking';

const TrlKeys = {
    published: 'employer.vacancy.changesHistory.publish',
    created: 'employer.vacancy.changesHistory.create',
    site: 'employer.vacancy.changesHistory.jobSite.HH',
    expires: 'employer.vacancy.changesHistory.expires',
    autoprolongation: 'employer.vacancies.autoProlongation.plannedBy',
    code: 'vacancy.header.vacancyCode',
    stat: 'vacancy.stat.link',
    lastsnapshot: 'vacancy.lastVacancySnapshot.link',
};

interface VacancyEmployerSidebarProps {
    dispatchModal: Dispatch<AnyAction>;
}

const VacancyEmployerSidebar: TranslatedComponent<VacancyEmployerSidebarProps> = ({ dispatchModal, trls }) => {
    const vacancyView = useSelector((state) => state.vacancyView);
    const userType = useSelector((state) => state.userType);
    const isNotHiringManager = useSelector((state) => !state.isHiringManager);
    const isBackofficeUser = userType === UserType.BackOffice;
    const canEditAuction = useAuctionEditingEnabled();
    const auctionCreationEnabled = useAuctionCreationEnabled();
    const { isGtS, isGtM } = useBreakpoint();
    const isShowAuctionBanner = isNotHiringManager && auctionCreationEnabled && isGtS;
    const isShowAuctionStatistic = isNotHiringManager && canEditAuction;
    const isVacancySuitableResumesExp = useExperiment('vacancy_block_suitable_resumes');
    const { isBranded } = useBranding();

    if (!vacancyView.systemInfo) {
        return null;
    }

    return (
        <div className="vacancy-history">
            <VSpacingContainer default={24}>
                {isGtS && (
                    <VacancyImportantBanners
                        vacancyId={String(vacancyView.vacancyId)}
                        vacancyName={vacancyView.name}
                        dispatchModal={dispatchModal}
                    />
                )}
                {isVacancySuitableResumesExp ? (
                    <>
                        {(isGtM || (isGtS && !isBranded)) && (
                            <VacancySuitableResumesBanner vacancyId={vacancyView.vacancyId as number} />
                        )}
                    </>
                ) : (
                    <SuitableVacanciesBanner vacancyId={String(vacancyView.vacancyId)} />
                )}
                {isShowAuctionBanner && <AuctionBanner />}
                {isShowAuctionStatistic && <AuctionStatistic />}
            </VSpacingContainer>
            {isBackofficeUser && (
                <>
                    <VSpacing default={12} />
                    <MagritteLink
                        Element={SPALink}
                        to={`/admin/vacancy/snapshot.do?vacancyId=${vacancyView.vacancyId as number}`}
                        style="neutral"
                    >
                        {trls[TrlKeys.lastsnapshot]}
                    </MagritteLink>
                    <VSpacing default={12} />
                    <DisableCallTracking vacancyId={vacancyView.vacancyId as number} />
                    <VSpacing default={12} />
                </>
            )}
        </div>
    );
};

export default translation(VacancyEmployerSidebar);
