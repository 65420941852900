import { Dispatch, FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios, { AxiosError } from 'axios';
import { AnyAction } from 'redux';

import { ArrowUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import {
    toggleVacancyOptionError,
    ToggleVacancyOptionErrorKey,
    toggleVacancyOptionSuccess,
    VacancyToggleAction,
} from 'src/components/Notifications/EmployerVacancies';
import { useNotification } from 'src/components/Notifications/Provider';
import { ADD_MODAL, MODAL_BUY_OPTION } from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { refreshManagerVacancy } from 'src/models/employerVacancies/managerVacancies';
import fetcher from 'src/utils/fetcher';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';

const ACTION_ERROR_KEYS = Object.values(ToggleVacancyOptionErrorKey).filter(
    (item) => item !== ToggleVacancyOptionErrorKey.NotEnoughServices
);

const TrlKeys = {
    [VacancyToggleAction.Enable]: 'vacancy.enableOption.trigger',
    [VacancyToggleAction.Disable]: 'vacancy.disableOption.trigger',
};

const TOGGLE_VACANCY_OPTION_URL = '/shards/employer/vacancies_dashboard/toggle_vacancy_option';
declare global {
    interface FetcherPostApi {
        [TOGGLE_VACANCY_OPTION_URL]: {
            queryParams: {
                vacancyId: number;
                action: VacancyToggleAction;
            };
            body: null;
            response: {
                query: string;
            };
        };
    }
}

interface FetcherErrorData {
    error: {
        key: ToggleVacancyOptionErrorKey;
    }[];
}
type GenericServerError = AxiosError<FetcherErrorData>;
const isGenericServerError = (error: unknown): error is GenericServerError =>
    axios.isAxiosError(error) && !!(error as GenericServerError).response?.data?.error;

interface OptionActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    dispatchModal: Dispatch<AnyAction>;
    onClose: VoidFunction;
    vacancyId: number;
    vacancyName: string;
    hasActiveOption: boolean;
}

const triggerName = 'option-toggle';

const OptionAction: TranslatedComponent<OptionActionProps> = ({
    Component,
    triggerType,
    hasActiveOption,
    vacancyId,
    vacancyName,
    additionalAnalyticsParams,
    dispatchModal,
    onClose,
    trls,
}) => {
    const action = hasActiveOption ? VacancyToggleAction.Disable : VacancyToggleAction.Enable;
    const [isLoading, setLoading] = useState<boolean>(false);
    const location = useSelector((state) => state.router.location);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const push = usePush();
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <ArrowUpOutlinedSize24 />,
    });

    const openModal = useCallback(() => {
        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType: MODAL_BUY_OPTION,
            },
        });
    }, [dispatchModal]);

    const toggleOption = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetcher.post(TOGGLE_VACANCY_OPTION_URL, null, {
                params: {
                    vacancyId,
                    action,
                },
            });
            if (location.pathname.match('/vacancy/')) {
                push(`${location.pathname}${response.data.query}`);
            } else {
                const vacancyResponse = await fetcher.get('/shards/employer/vacancies/get_full_vacancy', {
                    params: { vacancyId: [vacancyId] },
                });
                if (vacancyResponse) {
                    dispatch(refreshManagerVacancy(vacancyResponse?.vacancies?.[0]));
                    addNotification(toggleVacancyOptionSuccess, { props: { vacancyName, action } });
                }
            }
        } catch (error) {
            if (isGenericServerError(error)) {
                const errorKey = error.response?.data.error[0]?.key as ToggleVacancyOptionErrorKey;
                if (ACTION_ERROR_KEYS.includes(errorKey)) {
                    addNotification(toggleVacancyOptionError, { props: { errorKey } });
                } else if (errorKey === ToggleVacancyOptionErrorKey.NotEnoughServices) {
                    openModal();
                } else {
                    defaultRequestErrorHandler(error, addNotification);
                }
            }
        } finally {
            onClose?.();
            setLoading(false);
        }
    }, [action, addNotification, dispatch, location.pathname, onClose, openModal, push, vacancyId, vacancyName]);

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={toggleOption}
            isPermitted
            isLoading={isLoading}
            additionalAnalyticsParams={{ ...additionalAnalyticsParams, action }}
            {...iconProps}
        >
            {trls[TrlKeys[action]]}
        </Component>
    );
};

export default translation(OptionAction);
