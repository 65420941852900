import { useState, RefObject } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Tooltip, Button, VSpacing, Text, Title } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import scrollToElement from 'bloko/common/scrollToElement';

import Notices from 'Modules/Notices';
import StaticImg from 'src/components/StaticImg';
import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { AnalyzeResultType } from 'src/models/canvacVacancySalaryData';
import { VacancyViewTab } from 'src/models/vacancyViewTab';

import styles from './tooltip-onboarding.less';

const TrlKeys = {
    title: 'employer.vacancy.tab.stat.onboarding.title',
    text: 'employer.vacancy.tab.stat.onboarding.text',
    buttons: {
        show: 'employer.vacancy.tab.stat.onboarding.btn.show',
        great: 'employer.vacancy.tab.stat.onboarding.btn.great',
    },
};

const INFOTIP_NAME = 'vacancyTabCompetitorsOnboarding';

interface TooltipOnboardingProps {
    activatorRef: RefObject<HTMLSpanElement>;
}

const TooltipOnboarding: TranslatedComponent<TooltipOnboardingProps> = ({ activatorRef, trls }) => {
    const [show, setShow] = useState(true);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const showTip = useSelector((state) => show && state.infoTip.name === INFOTIP_NAME);

    const isHHRu = useIsHhru();
    const canvacSalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const activeTab = useSelector((state) => state.vacancyViewTab.active);
    const isVacancyTabCompetitorsExp = useExperiment(
        'vacancy_tab_competitors',
        isHHRu && activeTab === VacancyViewTab.Stat
    );

    const visible =
        showTip &&
        isVacancyTabCompetitorsExp &&
        canvacSalaryData?.analyzeResultType === AnalyzeResultType.CalculatedFull;

    const tooltipRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_onboarding',
        vacancyId,
    });

    const closeOutside = () => setShow(false);

    const closeTipHandler = () => {
        setShow(false);
        Notices.markAsViewed(INFOTIP_NAME);
    };

    const onCloseHandler = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_onboarding_great', { vacancyId });
        closeTipHandler();
    };

    const onScrollHandler = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_onboarding_show', { vacancyId });
        const section = document.querySelector(`#${ANCHOR_TO_SECTION.AnalysisAndCompetitors}`);
        if (section) {
            scrollToElement(section, { centered: false, topOffset: -20 });
        }
        closeTipHandler();
    };

    return (
        <Tooltip
            visible={visible}
            activatorRef={activatorRef}
            closeByClickOutside
            onClose={closeOutside}
            placement="right-center"
            guardDistanceToActivator={28}
            buttons={
                <>
                    <Button
                        onClick={onCloseHandler}
                        style="contrast"
                        mode="secondary"
                        size="small"
                        stretched
                        data-qa="tab-stat-onboarding-btn-great"
                    >
                        {trls[TrlKeys.buttons.great]}
                    </Button>
                    <Button
                        onClick={onScrollHandler}
                        style="contrast"
                        mode="primary"
                        size="small"
                        stretched
                        data-qa="tab-stat-onboarding-btn-show"
                    >
                        {trls[TrlKeys.buttons.show]}
                    </Button>
                </>
            }
        >
            <div className={styles.tooltipOnboardingContainer} ref={tooltipRef}>
                <StaticImg
                    className={styles.tooltipOnboardingImg}
                    path="/images/hh/vacancy/onboarding.png"
                    alt={trls[TrlKeys.title]}
                />
            </div>
            <VSpacing default={16} />
            <Title Element="h3" size="small">
                {trls[TrlKeys.title]}
            </Title>
            <Text>{trls[TrlKeys.text]}</Text>
        </Tooltip>
    );
};

export default translation(TooltipOnboarding);
