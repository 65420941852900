import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ManagerList from 'src/pages/VacancyView/components/VacancyManagersTab/ManagerList';
import ManagerListItem from 'src/pages/VacancyView/components/VacancyManagersTab/ManagerListItem';

const TrlKeys = {
    label: 'employer.vacancy.recruiter.managers.list.label',
};

const RecruiterManagersList: TranslatedComponent = ({ trls }) => {
    const recruiterManagers = useSelector((state) => state.vacancyView?.vacancyManagers?.recruiterManagers);

    if (!recruiterManagers || recruiterManagers.length === 0) {
        return null;
    }

    return (
        <ManagerList label={trls[TrlKeys.label]}>
            {recruiterManagers.map(({ id, fullName }) => (
                <ManagerListItem key={id}>{fullName}</ManagerListItem>
            ))}
        </ManagerList>
    );
};

export default translation(RecruiterManagersList);
