import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { GearOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import EditPermissionsModal, { EditPermissionsModalProps } from 'src/components/HiringManager/EditPermissionsModal';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { ShortHiringManagerData, vacancyUpdateHiringManagerPermissionsAction } from 'src/models/vacancyView';

interface EditHiringManagerSettingsActionProps {
    manager: ShortHiringManagerData;
    vacancyId: string;
}

const TrlKeys = {
    triggerText: 'employer.vacancy.managers.editHiringManagerSettings.modal.trigger.text',
};

const EditHiringManagerSettingsAction: TranslatedComponent<EditHiringManagerSettingsActionProps> = ({
    trls,
    manager,
    vacancyId,
}) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();
    const [isOpenModal, openModal, closeModal] = useOnOffState(false);

    const handleUpdatePermissions = useCallback<NonNullable<EditPermissionsModalProps['onSuccessSubmit']>>(
        (permissions) => {
            dispatch(vacancyUpdateHiringManagerPermissionsAction({ managerId: manager.id, permissions }));
        },
        [dispatch, manager.id]
    );

    return (
        <>
            <Button
                mode="tertiary"
                size="small"
                icon={<GearOutlinedSize16 />}
                hideLabel={isMobile}
                aria-label={isMobile ? trls[TrlKeys.triggerText] : ''}
                onClick={openModal}
            >
                {trls[TrlKeys.triggerText]}
            </Button>
            <EditPermissionsModal
                vacancyId={vacancyId}
                employerManagerId={manager.id}
                permissions={manager.permissions}
                isMcpPermissions={manager.withMcpPermission}
                visible={isOpenModal}
                handleCloseModal={closeModal}
                onSuccessSubmit={handleUpdatePermissions}
            />
        </>
    );
};

export default translation(EditHiringManagerSettingsAction);
