import { useCallback } from 'react';

import { useNotification } from 'src/components/Notifications/Provider';
import jobSearchStatusAfterResponse from 'src/components/VacancyResponseForm/JobSearchStatusNotification';
import resumeAutoHideNotification from 'src/components/VacancyResponseForm/ResumeAutoHideNotification';
import { vacancyResponse } from 'src/components/VacancyResponseForm/VacancyResponseNotification';
import { useSelector } from 'src/hooks/useSelector';

const useDispatchAfterResponseNotification = () => {
    const publishedResumeHash = useSelector((state) => state.publishedForResponseResumeHash);
    const usedResume = useSelector((state) => state.vacancyView.responseInfo?.respondedWithResume);
    const { addNotification } = useNotification();

    return useCallback(
        (askJobSearchStatus, notificationParams) => {
            if (usedResume && usedResume.autoHideTime && publishedResumeHash === usedResume.hash) {
                addNotification(resumeAutoHideNotification, {
                    props: {
                        resumeHash: publishedResumeHash,
                        autoHideTime: usedResume.autoHideTime[0].string,
                    },
                });
                return;
            }

            if (askJobSearchStatus) {
                addNotification(jobSearchStatusAfterResponse, { props: notificationParams, uniqueType: true });
                return;
            }

            addNotification(vacancyResponse, { props: notificationParams });
        },
        [usedResume, publishedResumeHash, addNotification]
    );
};

export default useDispatchAfterResponseNotification;
