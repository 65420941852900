import { FC } from 'react';

import { Avatar, Cell, CellText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { VacancyCompany, VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

import styles from './styles.less';

interface CompanyProps {
    id: VacancyCompany['id'];
    visibleName: VacancyCompany['visibleName'];
    department: VacancyCompany['department'];
    logo?: string;
    area: VacancySearchItem['area'];
    isMagritteVacancy?: boolean;
}

const Company: FC<CompanyProps> = ({ id, visibleName, department, logo, area, isMagritteVacancy }) => {
    const areaContent = area ? `, ${area.name}` : null;
    const content = isMagritteVacancy ? (
        <Cell
            left={
                <div className={styles.avatarWrapper}>
                    <Avatar
                        shapeCircle
                        size={40}
                        mode="image"
                        image={logo}
                        fallbackMode="placeholder"
                        placeholder="city"
                        aria-label={visibleName}
                    />
                </div>
            }
        >
            <CellText maxLines={1}>{visibleName}</CellText>
        </Cell>
    ) : (
        <>
            {visibleName}
            {areaContent}
        </>
    );

    if (!id) {
        return content;
    }

    const departmentCode = department?.['@code'] || '';
    const departmentQuery = departmentCode ? `?dpt=${departmentCode}` : '';
    const employerUrl = `/employer/${id}${departmentQuery}`;

    if (isMagritteVacancy) {
        return (
            <MagritteLink style="neutral" Element={Link} to={employerUrl} target="_blank">
                {content}
            </MagritteLink>
        );
    }

    return (
        <BlokoLink kind={LinkKind.Secondary} Element={Link} to={employerUrl} target="_blank">
            {content}
        </BlokoLink>
    );
};

export default Company;
