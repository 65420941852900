import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';

import VSpacingContainer from 'bloko/blocks/vSpacing/VSpacingContainer';

const MenuContent: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren> = ({ children }, ref) => {
    return (
        <div ref={ref}>
            <VSpacingContainer base={2}>{children}</VSpacingContainer>
        </div>
    );
};

export default forwardRef(MenuContent);
