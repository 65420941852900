import { scaleLinear, scaleTime } from 'd3-scale';
import { area, curveMonotoneX, line } from 'd3-shape';

import {
    LineChartData,
    LineChartPoint,
    LineChartRawData,
    XAxis,
    YAxis,
} from 'src/pages/VacancyView/components/VacancyStatTab/Chart/types';

const d3 = { scaleLinear, scaleTime, area, line };

interface BorderValues {
    date: {
        min: Date;
        max: Date;
    };
    value: {
        min: number;
        max: number;
    };
}

/** Возвращает минимальное и максимальное значения для осей OX (date) и OY (number) */
export const getBorderAxesValues = (data: LineChartRawData): BorderValues => {
    const minDate = new Date(data[0].date);
    const maxDate = new Date(data[data.length - 1].date);

    const values = data.map((point) => point.value);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    return {
        date: {
            min: minDate,
            max: maxDate,
        },
        value: {
            min: minValue,
            max: maxValue,
        },
    };
};

export const getPreparedData = (lineData: LineChartRawData): LineChartData => {
    return lineData.map((point) => {
        const additionalData = point.additional ? { additional: point.additional } : {};
        return {
            rawDate: point.date,
            date: new Date(point.date),
            value: point.value,
            ...additionalData,
        };
    });
};

export const getXAxis = (min: Date, max: Date, width: number): XAxis =>
    d3.scaleTime().domain([min, max]).range([0, width]);

export const getYAxis = (min: number, max: number, height: number): YAxis =>
    d3.scaleLinear().domain([min, max]).range([height, 0]);

export const getLinePath = (
    preparedData: LineChartData,
    xAxis: XAxis,
    yAxis: YAxis,
    isCurveChart = false
): string | null => {
    const lineChart = d3
        .line<LineChartPoint>()
        .defined((point) => point.value !== null)
        .x((point) => xAxis(point.date))
        .y((point) => yAxis(point.value));

    if (isCurveChart) {
        lineChart.curve(curveMonotoneX);
    }

    const filteredLineData = preparedData.filter(lineChart.defined());
    return lineChart(filteredLineData);
};

export const getAreaPath = (
    preparedData: LineChartData,
    xAxis: XAxis,
    yAxis: YAxis,
    yMin: number,
    isCurveChart = false
): string | null => {
    const areaChart = d3
        .area<LineChartPoint>()
        .defined((point) => point.value !== null)
        .x((point) => xAxis(point.date))
        .y0(yAxis(yMin))
        .y1((point) => yAxis(point.value));

    if (isCurveChart) {
        areaChart.curve(curveMonotoneX);
    }

    const filteredLineData = preparedData.filter(areaChart.defined());
    return areaChart(filteredLineData);
};
