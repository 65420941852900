import { FC } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import {
    BasicTriggerProps,
    BasicTriggerPropsWithoutTriggerName,
    ControlsTriggerActionType,
} from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';

interface PfpTopupActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    displayInOwnColumn: boolean;
    hhtmFromLabel: string;
}

const TrlKeys = {
    deposit: 'pfp.balanceMessage.deposit',
};

const PfpTopupAction: TranslatedComponent<PfpTopupActionProps> = ({
    Component,
    displayInOwnColumn,
    hhtmFromLabel,
    icon,
    triggerType,
    trls,
}) => {
    return (
        <Component
            triggerName="pfp-topup"
            triggerType={triggerType}
            actionType={ControlsTriggerActionType.LIGHT}
            triggerHref={`${paths.pricePayForPerformance}?hhtmFromLabel=${hhtmFromLabel}`}
            displayInOwnColumn={displayInOwnColumn}
            icon={icon}
            isPermitted
        >
            {trls[TrlKeys.deposit]}
        </Component>
    );
};

export default translation(PfpTopupAction);
