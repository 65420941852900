import { ReactNode, Fragment, useEffect } from 'react';

import vacancyBrandySnippetInfoModalScreenShown from '@hh.ru/analytics-js-events/build/xhh/employer/branding/brandy_snippet/vacancy_brandy_snippet_info_modal_screen_shown';
import {
    Action,
    Button,
    Modal,
    BottomSheet,
    useBreakpoint,
    VSpacing,
    Text,
    NavigationBar,
    BottomSheetFooter,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const newLineToSpacer = (trlValue: string, spacer: ReactNode): JSX.Element => {
    const splitValues = trlValue.split(/\n/gi);

    const lastIndex = splitValues.length - 1;
    return (
        <>
            {splitValues.map((row, index) => (
                <Fragment key={index}>
                    {row}
                    {index < lastIndex && spacer}
                </Fragment>
            ))}
        </>
    );
};

const TrlKeys = {
    header: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.modal.header',
    content: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.modal.content',
    close: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.modal.close',
};

interface InformationOverlayProps {
    onClose: () => void;
    visible: boolean;
}

const InformationOverlay: TranslatedComponent<InformationOverlayProps> = ({ onClose, visible, trls }) => {
    const { isMobile } = useBreakpoint();
    const { vacancyId } = useSelector((state) => state.vacancyView);

    const children = newLineToSpacer(trls[TrlKeys.content], <VSpacing default={8} />);

    const commonProps = {
        visible,
        onClose,
        children,
    };

    useEffect(() => {
        if (visible && vacancyId) {
            vacancyBrandySnippetInfoModalScreenShown({ vacancyId });
        }
    }, [vacancyId, visible]);

    if (isMobile) {
        return (
            <BottomSheet
                {...commonProps}
                header={<NavigationBar title={trls[TrlKeys.header]} />}
                footer={
                    <BottomSheetFooter>
                        <Button
                            mode="secondary"
                            style="accent"
                            onClick={onClose}
                            data-qa="vacancy-important-banner-branded-snippet-bottomsheet-close"
                        >
                            {trls[TrlKeys.close]}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                <Text typography="label-2-regular">{children}</Text>
            </BottomSheet>
        );
    }
    return (
        <Modal
            actions={
                <Action
                    mode="secondary"
                    onClick={onClose}
                    icon={CrossOutlinedSize24}
                    data-qa="vacancy-important-banner-branded-snippet-modal-action-close"
                />
            }
            title={trls[TrlKeys.header]}
            footer={
                <ActionBar
                    primaryActions={
                        <Button
                            mode="primary"
                            style="accent"
                            onClick={onClose}
                            data-qa="vacancy-important-banner-branded-snippet-modal-close"
                        >
                            {trls[TrlKeys.close]}
                        </Button>
                    }
                />
            }
            {...commonProps}
        >
            <Text typography="paragraph-1-regular">{children}</Text>
        </Modal>
    );
};

export default translation(InformationOverlay);
