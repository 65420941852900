import { Dispatch, FC, memo, ReactElement, useCallback, useEffect, useRef } from 'react';
import { AnyAction } from 'redux';

import { ActionList, Button } from '@hh.ru/magritte-ui';
import { EllipsisHorizontalOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import {
    BasicTriggerPropsWithoutTriggerName,
    ControlsTriggerActionType,
    TriggerType,
} from 'src/components/ControlsTrigger';
import { useShownRawAnalyticsWithInnerButtons } from 'src/hooks/useMyVacanciesAnalytics';
import useToggleState from 'src/hooks/useToggleState';

import MenuContent from 'src/components/AbstractControls/MenuContent';

import styles from './abstract-controls.less';

const MENU_BUTTON_MAX_WIDTH = 360;

interface ControlComponentProps extends BasicTriggerPropsWithoutTriggerName {
    onClose?: VoidFunction;
    onCancel?: VoidFunction;
    isLoading?: boolean;
    isPermitted?: boolean;
    dispatchModal?: Dispatch<AnyAction>;
    permissions?: string[];
    onSuccessAction?: VoidFunction;
    iconOnly?: boolean;
    displayInOwnColumn?: boolean;
    afterSuccessAction?: () => void;
}

export interface Control {
    name: string;
    condition: boolean;
    Component: FC<ControlComponentProps>;
    additionalProps?: Record<string, unknown>;
    actionType?: ControlsTriggerActionType;
    icon?: ReactElement;
}

export interface AbstractControlsProps {
    controlsList: Control[];
    controlProps: Record<string, unknown>;
    displayAsMenu?: boolean;
    analyticsOnMenuShow?: string;
    dataQa: {
        menu: string;
        button: string;
    };
    actionListPlacement?: `${'right' | 'left'}-${'top' | 'bottom'}`;
    onMenuStateChange?: (visible: boolean) => void;
}

const AbstractControls: FC<AbstractControlsProps> = ({
    controlsList,
    controlProps,
    displayAsMenu = false,
    analyticsOnMenuShow,
    dataQa,
    onMenuStateChange,
    actionListPlacement = 'right-top',
}) => {
    const [isMenuVisible, toggleMenu, setMenuVisible] = useToggleState(false);

    const menuRef = useRef(null);
    const activatorRef = useRef(null);
    const shownMenuRawAnalytics = useShownRawAnalyticsWithInnerButtons(menuRef);

    useEffect(() => {
        onMenuStateChange?.(isMenuVisible);
    }, [onMenuStateChange, isMenuVisible]);

    const renderTriggers = useCallback(
        (triggerType: TriggerType, onClose?: () => void) => (
            <>
                {controlsList.map(
                    ({ name, condition, Component, actionType, additionalProps }) =>
                        condition && (
                            <Component
                                key={name}
                                triggerType={triggerType}
                                onClose={onClose}
                                actionType={actionType}
                                {...controlProps}
                                {...additionalProps}
                            />
                        )
                )}
            </>
        ),
        [controlProps, controlsList]
    );

    const renderMenuTriggers = useCallback(
        () => <MenuContent ref={menuRef}>{renderTriggers(TriggerType.ButtonMenu, toggleMenu)}</MenuContent>,
        [toggleMenu, renderTriggers]
    );

    if (displayAsMenu) {
        const toggleMenuAndSendAnalytics = () => {
            toggleMenu();

            if (!isMenuVisible && analyticsOnMenuShow) {
                shownMenuRawAnalytics(analyticsOnMenuShow);
            }
        };

        return (
            <div data-qa={dataQa.menu}>
                <div className={styles.abstractControlsMenuButtonWrapper}>
                    <Button
                        ref={activatorRef}
                        mode="secondary"
                        hideLabel
                        aria-label="Controls"
                        onClick={toggleMenuAndSendAnalytics}
                        icon={<EllipsisHorizontalOutlinedSize24 initial="primary" />}
                        data-qa={dataQa.button}
                    />
                </div>
                <ActionList
                    visible={isMenuVisible}
                    onClose={() => setMenuVisible(false)}
                    dropProps={{
                        activatorRef,
                        role: 'status',
                        placement: actionListPlacement,
                        allowShrinkHeightToFitIntoViewport: false,
                        maxWidth: MENU_BUTTON_MAX_WIDTH,
                    }}
                >
                    {renderMenuTriggers()}
                </ActionList>
            </div>
        );
    }

    return renderTriggers(TriggerType.Link);
};

export default memo(AbstractControls);
