import format from 'date-fns/format';

import { addDays, toDate } from 'bloko/blocks/calendar/datesHelper';

import formatDate from 'Modules/formatDate';
import {
    Publication,
    VacancyConversionStatistics,
    VacancyPublicationType,
} from 'src/models/vacancyConversionStatistics';

import ICON_ARROW_UP from 'src/pages/VacancyView/components/VacancyStatTab/Chart/IconArrowUp';
import {
    RectangleChartPoint,
    RectangleChartIconPoint,
} from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/types';
import Color from 'src/pages/VacancyView/components/VacancyStatTab/Chart/color';

const last = <T extends unknown[]>(arr: T): T[number] | undefined => {
    const [lastItem] = arr.slice(-1);
    return lastItem;
};

const getPublicationColor = (type: string): Color => {
    const PUBLICATION_TO_COLOR: Record<string, Color> = {
        [VacancyPublicationType.Standard]: Color.Gray40,
        [VacancyPublicationType.ZPDemo]: Color.Gray40,
        [VacancyPublicationType.StandardPlus]: Color.Gray60,
        [VacancyPublicationType.ZPPromo]: Color.Gray60,
        [VacancyPublicationType.Premium]: Color.Violet40,
        [VacancyPublicationType.ZPBusiness]: Color.Violet40,
        [VacancyPublicationType.ZPAnonymous]: Color.Gray20,
        [VacancyPublicationType.Free]: Color.Gray20,
        [VacancyPublicationType.PFP]: Color.Gray30,
    };
    return PUBLICATION_TO_COLOR[type.toUpperCase()] ?? Color.Violet40;
};

const normalizePublicationData = (
    data: Publication[],
    interval: { startDate: string; endDate: string }
): Publication[] => {
    const isOneDay = data.length === 1;

    const publicationPoints = data.reduce(
        (acc, cur) => {
            // исключить пограничные значения, выходящие за рамки графика, и период более одного дня
            if (cur.startDate > interval.endDate && !isOneDay) {
                return acc;
            }

            const startDate =
                cur.startDate < interval.startDate || cur.startDate === null ? interval.startDate : cur.startDate;

            if (acc[startDate]) {
                acc[startDate].publicationType = cur.publicationType;
                return acc;
            }

            acc[startDate] = {
                ...cur,
                startDate,
            };
            return acc;
        },
        {} as Record<string, Publication>
    );

    return Object.values(publicationPoints);
};

export const preparePublicationData = (
    data: Publication[],
    interval: { startDate: string; endDate: string },
    trls: (type: VacancyPublicationType) => string
): RectangleChartPoint[] => {
    const publicationPoints: RectangleChartPoint[] = [];

    normalizePublicationData(data, interval).forEach(
        (cur, ind, arr) => {
            const { startDate, publicationType } = cur;
            const nextIndex = ind + 1;
            const endDate = arr[nextIndex] ? arr[nextIndex].startDate : interval.endDate;

            const point = {
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                color: getPublicationColor(publicationType),
                title: trls(publicationType.toUpperCase() as VacancyPublicationType),
            };
            publicationPoints.push(point);
        },
        {} as Record<string, RectangleChartPoint>
    );

    return publicationPoints;
};

export const prepareProlongationIcons = (data: Publication[]): RectangleChartIconPoint[] => {
    const uniqPoints = new Set(data.flatMap((p) => p.prolongations));

    return [...uniqPoints].map((dt) => ({
        startDate: new Date(dt),
        color: Color.Violet60,
        icon: ICON_ARROW_UP,
    }));
};

export const prepareReportData = (report: VacancyConversionStatistics | null): VacancyConversionStatistics | null => {
    if (!report) {
        return null;
    }

    const { points, publications, endDate } = report;
    const lastPublication = last(publications);
    const lastPoint = last(points);

    // расширение графика за счет фейковой точки выполняется в случае:
    // есть публикация на endDate графика
    // кол-во точек === 1
    if ((lastPublication?.startDate !== endDate && points.length !== 1) || !lastPoint) {
        return report;
    }

    const lastPointDate = toDate(lastPoint.date);
    const newPointDate = format(addDays(lastPointDate, 1), 'yyyy-MM-dd');
    const newPoint = {
        ...lastPoint,
        date: newPointDate,
        isFake: true,
    };

    return {
        ...report,
        endDate: newPointDate,
        points: [...report.points, newPoint],
    };
};

/**
 * Возвращает интервал дат, объединяя одинаковые даты, месяцы и года, например
 * - 31.12.2020 - 31.12.2020 -> 31 декабря 2020
 * - 11.02.2020 - 15.02.2020 -> 11 февраля – 15 февраля 2020
 * - 01.01.2020 - 01.02.2020 -> 1 января – 1 февраля 2020
 * - 01.01.2020 - 01.01.2021 -> 1 января 2020 – 1 января 2021
 * @param short - использовать сокращенную запись месяца 1.01.2020 вместо 1 января 2020
 */
export const formatDatesInterval = (start: Date, end: Date, short: boolean): string => {
    const formatFull = short ? 'd.MM.yyyy' : 'd MMMM yyyy';
    const formatWithoutYear = short ? 'd.MM' : 'd MMMM';

    const formattedStartDate = formatDate(start, formatFull);
    const formattedEndDate = formatDate(end, formatFull);

    if (!formattedStartDate || !formattedEndDate) {
        return '';
    }
    if (formattedStartDate === formattedEndDate) {
        return formattedEndDate;
    }
    if (start.getFullYear() === end.getFullYear()) {
        const formattedStartDateWithoutYear = formatDate(start, formatWithoutYear);
        return `${formattedStartDateWithoutYear} – ${formattedEndDate}`;
    }
    return `${formattedStartDate} – ${formattedEndDate}`;
};
