import { useCallback, useRef, useEffect, MutableRefObject } from 'react';

import throttle from 'bloko/common/throttle';

export interface LayoutCallbackFunc {
    (triggerName: string, el: React.MutableRefObject<HTMLDivElement>): void;
}

export interface UseResponsiveMenuReturn {
    containerRef: MutableRefObject<HTMLDivElement | null>;
    layoutCallback: LayoutCallbackFunc;
}

/**
 * Определяем кнопки действий по вакансии которые необходимо собрать в меню
 */
const useAdaptiveVacancyActions = (setMenu: (menuItems: string[]) => void): UseResponsiveMenuReturn => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const actionElementsRef = useRef<Record<string, HTMLDivElement>>({});

    const layoutCallback = useCallback((triggerName: string, wrapperRef: MutableRefObject<HTMLDivElement>) => {
        actionElementsRef.current = {
            ...actionElementsRef.current,
            [triggerName]: wrapperRef.current,
        };
    }, []);

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        const resizeCallback = () => {
            // reset all vacancy actions elements
            Object.values(actionElementsRef.current).forEach((el) => {
                if (el) {
                    el.style.display = '';
                }
            });

            // define elements that hide to menu
            const collectMenu = Object.entries(actionElementsRef.current)
                .filter(([, el]) => el.offsetTop !== 0)
                .map(([triggerName]) => triggerName);

            // hide elements that moved into menu
            collectMenu.forEach((triggerName) => {
                if (actionElementsRef.current.hasOwnProperty(triggerName)) {
                    actionElementsRef.current[triggerName].style.display = 'none';
                }
            });
            setMenu(collectMenu);
        };

        const resizeObserver = new ResizeObserver(throttle(resizeCallback, 100));
        resizeObserver.observe(containerRef.current);

        return () => resizeObserver?.disconnect();
    }, [setMenu]);

    return {
        containerRef,
        layoutCallback,
    };
};

export default useAdaptiveVacancyActions;
