import { FC, useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import { TriggerType } from 'src/components/ControlsTrigger';
import VacancyTrigger from 'src/components/EmployerVacancies/VacancyTrigger';
import updateVacancyInfoAfterProlongate from 'src/components/EmployerVacancies/updateVacancyInfoAfterProlongate';
import { useNotification } from 'src/components/Notifications/Provider';
import ProlongateAction from 'src/components/VacancyActions/ProlongateAction';
import Modals, { modalReducer } from 'src/components/VacancyModals';
import { CLOSE_MODAL } from 'src/components/VacancyModals/constants';
import { ActionButtonProps } from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/types';

type RaiseVacancyButtonProps = Pick<ActionButtonProps, 'vacancyId' | 'sendEventClick'>;

const RaiseVacancyButton: FC<RaiseVacancyButtonProps> = ({ vacancyId, sendEventClick }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const [modalData, dispatchModal] = useReducer(modalReducer, {});
    const handleCloseModal = useCallback(() => dispatchModal({ type: CLOSE_MODAL }), []);

    const onButtonClick = () => {
        sendEventClick('vacancy_view_stat_tab_advice_raise_vacancy');
    };

    return (
        <>
            <ProlongateAction
                Component={VacancyTrigger}
                key="prolongate"
                triggerType={TriggerType.VacancyAdviceCompetitionCardButton}
                vacanciesIds={[vacancyId]}
                dispatchModal={dispatchModal}
                afterSuccessAction={({ handleCloseModal, vacancyIds }) => {
                    dispatch(updateVacancyInfoAfterProlongate({ addNotification, vacancyIds }));
                    handleCloseModal();
                }}
                additionalAnalyticsParams={{
                    fromPlace: 'vacancy_advice_modal',
                    vacanciesIds: String(vacancyId),
                }}
                onClickCallback={onButtonClick}
            />

            <Modals dispatchModal={dispatchModal} modalData={modalData} handleCloseModal={handleCloseModal} />
        </>
    );
};

export default RaiseVacancyButton;
