import PropTypes from 'prop-types';

import Button, { ButtonKind, ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';

import VacancyActionResponse, { VacancyActionResponseKind } from 'src/components/VacancyView/VacancyActionResponse';
import translation from 'src/components/translation';

const VacancyViewsCount = ({ trls, removeNotification }) => {
    return (
        <>
            <div>{trls[VacancyViewsCount.trls.text]}</div>
            <NotificationFooter>
                <VacancyActionResponse
                    responseButtonProps={{
                        kind: ButtonKind.Inversed,
                        appearance: ButtonAppearance.Outlined,
                        scale: ButtonScale.Small,
                    }}
                    kind={VacancyActionResponseKind.Notification}
                    isSecondary
                />
                <FormSpacer />
                <Button
                    kind={ButtonKind.Inversed}
                    appearance={ButtonAppearance.Outlined}
                    scale={ButtonScale.Small}
                    onClick={() => {
                        removeNotification();
                    }}
                >
                    {trls[VacancyViewsCount.trls.close]}
                </Button>
            </NotificationFooter>
        </>
    );
};

VacancyViewsCount.trls = {
    close: 'vacancyViewsCount.notice.close',
    text: 'vacancyViewsCount.notice.text',
};

VacancyViewsCount.propTypes = {
    trls: PropTypes.object,
    removeNotification: PropTypes.func,
};

export default {
    Element: translation(VacancyViewsCount),
    kind: 'message',
    autoClose: false,
};
