import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import TransferVacancyAction from 'src/pages/VacancyView/components/VacancyManagersTab/Actions/TransferVacancyAction';
import ManagerList from 'src/pages/VacancyView/components/VacancyManagersTab/ManagerList';
import ManagerListItem from 'src/pages/VacancyView/components/VacancyManagersTab/ManagerListItem';

const TrlKeys = {
    label: 'employer.vacancy.owner.manager.block.label',
};

const OwnerManagerBlock: TranslatedComponent = ({ trls }) => {
    const owner = useSelector((state) => state.vacancyView?.vacancyManagers?.owner);

    if (!owner) {
        return null;
    }

    return (
        <ManagerList label={trls[TrlKeys.label]}>
            <ManagerListItem actions={<TransferVacancyAction />}>{owner.fullName}</ManagerListItem>
        </ManagerList>
    );
};

export default translation(OwnerManagerBlock);
