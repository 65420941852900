import { ReactElement } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { SalaryRange } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SalaryInfo from 'src/components/SalaryInfo';
import { getTextStyle } from 'src/components/VacancyImportantBanners/CanvacBanner';
import translation from 'src/components/translation';
import { MarketRange } from 'src/models/canvacVacancySalaryData';
import { VacancySalaryAdvice } from 'src/models/vacancy/vacancyAdvices.types';
import { NON_BREAKING_SPACE, EM_DASH, COLON } from 'src/utils/constants/symbols';

import {
    NO_SALARY_ADVICE,
    NoSalaryAdvice,
} from 'src/pages/VacancyView/components/VacancyStatTab/SalaryRangeCard/constants';

const TrlKeys = {
    [SalaryRange.NotSpecified]: {
        title: 'employer.vacancy.tab.stat.salaryRange.NOT_SPECIFIED.title',
        colorText: 'employer.vacancy.tab.stat.salaryRange.NOT_SPECIFIED.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.NOT_SPECIFIED.description',
    },
    [SalaryRange.TooWide]: {
        title: 'employer.vacancy.tab.stat.salaryRange.TOO_WIDE.title',
        afterTitle: 'employer.vacancy.tab.stat.salaryRange.TOO_WIDE.afterTitle',
        colorText: 'employer.vacancy.tab.stat.salaryRange.TOO_WIDE.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.TOO_WIDE.description',
    },
    [SalaryRange.TooLow]: {
        title: 'employer.vacancy.tab.stat.salaryRange.TOO_LOW.title',
        afterTitle: 'employer.vacancy.tab.stat.salaryRange.TOO_LOW.afterTitle',
        colorText: 'employer.vacancy.tab.stat.salaryRange.TOO_LOW.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.TOO_LOW.description',
    },
    [SalaryRange.Low]: {
        title: 'employer.vacancy.tab.stat.salaryRange.LOW.title',
        afterTitle: 'employer.vacancy.tab.stat.salaryRange.LOW.afterTitle',
        colorText: 'employer.vacancy.tab.stat.salaryRange.LOW.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.LOW.description',
    },
    [SalaryRange.MatchMarket]: {
        title: 'employer.vacancy.tab.stat.salaryRange.MATCH_MARKET.title',
        afterTitle: 'employer.vacancy.tab.stat.salaryRange.MATCH_MARKET.afterTitle',
        colorText: 'employer.vacancy.tab.stat.salaryRange.MATCH_MARKET.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.MATCH_MARKET.description',
    },
    [SalaryRange.High]: {
        title: 'employer.vacancy.tab.stat.salaryRange.HIGH.title',
        colorText: 'employer.vacancy.tab.stat.salaryRange.HIGH.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.HIGH.description',
    },
    [SalaryRange.TooHigh]: {
        title: 'employer.vacancy.tab.stat.salaryRange.TOO_HIGH.title',
        colorText: 'employer.vacancy.tab.stat.salaryRange.TOO_HIGH.colorText',
        description: 'employer.vacancy.tab.stat.salaryRange.TOO_HIGH.description',
    },
    noAdviceTitle: 'employer.vacancy.tab.stat.advice.NO_SALARY_ADVICE.title',
    noAdvice: {
        [SalaryRange.NotSpecified]: {
            colorText: 'employer.vacancy.tab.stat.salaryRange.noAdvice.NOT_SPECIFIED.colorText',
        },
    },
};

interface SalaryRangeCardHeadingProps {
    advice: VacancySalaryAdvice | NoSalaryAdvice;
    marketRange: MarketRange;
    salaryRange: Exclude<SalaryRange, SalaryRange.Undefined>;
}

const SalaryRangeCardHeading: TranslatedComponent<SalaryRangeCardHeadingProps> = ({
    advice,
    marketRange,
    salaryRange,
    trls,
}) => {
    const titlePath = advice === NO_SALARY_ADVICE ? TrlKeys.noAdviceTitle : TrlKeys[salaryRange].title;
    const colorTextStyle = getTextStyle(salaryRange, advice !== NO_SALARY_ADVICE);

    const colorTextPath =
        advice === NO_SALARY_ADVICE && salaryRange === SalaryRange.NotSpecified
            ? TrlKeys.noAdvice[salaryRange].colorText
            : TrlKeys[salaryRange].colorText;

    const colorText = (
        <Text Element="span" typography="label-2-regular" style={colorTextStyle}>
            {trls[colorTextPath]}
        </Text>
    );
    const emDashWithSpacing = (
        <>
            {NON_BREAKING_SPACE}
            {EM_DASH}
            {NON_BREAKING_SPACE}
        </>
    );

    const getTitle = (): ReactElement => {
        if (advice === NO_SALARY_ADVICE) {
            return (
                <>
                    {trls[titlePath]}
                    {emDashWithSpacing}
                    {colorText}
                </>
            );
        }

        switch (salaryRange) {
            case SalaryRange.NotSpecified:
                return (
                    <>
                        {trls[titlePath]}
                        <SalaryInfo
                            startText={`${COLON}${NON_BREAKING_SPACE}`}
                            from={marketRange.bottom}
                            to={marketRange.upper}
                            currency={marketRange.currency}
                        />
                        {emDashWithSpacing}
                        {colorText}
                    </>
                );

            case SalaryRange.TooLow:
            case SalaryRange.Low:
            case SalaryRange.MatchMarket:
            case SalaryRange.TooWide:
                return (
                    <>
                        {trls[titlePath]}
                        {NON_BREAKING_SPACE}
                        {colorText}
                        {emDashWithSpacing}
                        {trls[TrlKeys[salaryRange].afterTitle]}
                    </>
                );

            case SalaryRange.High:
            case SalaryRange.TooHigh:
                return (
                    <>
                        {trls[titlePath]}
                        {emDashWithSpacing}
                        {colorText}
                    </>
                );

            default:
                return (
                    <>
                        {trls[titlePath]}
                        {emDashWithSpacing}
                        {colorText}
                    </>
                );
        }
    };

    return (
        <>
            <Text Element="span" typography="label-2-regular" data-qa="tab-stat-salary-chart-title">
                {getTitle()}
            </Text>
            {advice !== NO_SALARY_ADVICE && (
                <>
                    <VSpacing default={8} />
                    <Text Element="span" style="secondary" typography="label-3-regular">
                        {trls[TrlKeys[salaryRange].description]}
                    </Text>
                </>
            )}
        </>
    );
};

export default translation(SalaryRangeCardHeading);
