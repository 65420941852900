import { FC, MutableRefObject, RefObject, useEffect, useRef } from 'react';

import { Button, ButtonStyle, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { MenuItem } from 'bloko/blocks/drop';

import { BasicTriggerProps, TriggerType } from 'src/components/ControlsTrigger';
import { sendEventClickAction, sendEventShownButton } from 'src/components/VacancyActions/sendVacancyActionsAnalytics';
import { LayoutCallbackFunc } from 'src/components/VacancyActions/useAdaptiveVacancyActions';
import useRunEmployerVacancyAction from 'src/hooks/useRunEmployerVacancyAction';

import VacancyActionLayout from 'src/pages/VacancyView/components/VacancyActionLayout';

export interface VacancyViewActionProps extends BasicTriggerProps {
    buttonsContainerRef: RefObject<HTMLDivElement>;
    sendAnaliticsRef: RefObject<string[]>;
    layoutCallback: LayoutCallbackFunc;
    vacancyId: number;
}

const VacancyViewAction: FC<VacancyViewActionProps> = ({
    children,
    isLoading,
    layoutCallback,
    onTrigger,
    triggerHref,
    triggerName,
    triggerType,
    buttonsContainerRef,
    sendAnaliticsRef,
    counter,
    vacancyId,
    icon,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { isXS } = useBreakpoint();

    useRunEmployerVacancyAction(triggerName, () => {
        if (onTrigger) {
            onTrigger(true);
        }
    });

    useEffect(() => {
        if (wrapperRef.current) {
            layoutCallback(triggerName, wrapperRef as MutableRefObject<HTMLDivElement>);
        }
    }, [layoutCallback, triggerName]);

    useEffect(() => {
        const sendAnaliticsArr = sendAnaliticsRef?.current;
        if (sendAnaliticsArr && !sendAnaliticsRef.current.includes(triggerName)) {
            sendEventShownButton(buttonsContainerRef, wrapperRef, triggerName, vacancyId);
            sendAnaliticsRef.current.push(triggerName);
        }
    }, [buttonsContainerRef, sendAnaliticsRef, triggerName, vacancyId, wrapperRef]);

    const onClick = () => {
        sendEventClickAction(triggerType, triggerName, vacancyId, !!triggerHref);
        if (onTrigger) {
            onTrigger();
        }
    };

    const targetProps = triggerName === 'print' ? { target: '_blank' } : {};

    if (triggerType === TriggerType.Button) {
        const style: ButtonStyle = ['responses-link', 'prolongate'].includes(triggerName) ? 'accent' : 'neutral';
        const mode = { mode: ['responses-link'].includes(triggerName) ? 'primary' : 'secondary' } as const;
        const stretched = isXS && triggerName === 'responses-link' ? { stretched: true } : {};
        const counterProp = counter && triggerName === 'responses-link' ? { postfix: counter } : {};
        const iconProp = icon ? { icon } : {};
        const otherProps = {
            'data-qa': `vacancies-dashboard-${triggerName}`,
            style,
            loading: isLoading,
            onClick,
            ...targetProps,
            ...mode,
            ...stretched,
            ...counterProp,
            ...iconProp,
        };

        return (
            <div className="vacancy-action" ref={wrapperRef}>
                <VacancyActionLayout>
                    {triggerHref ? (
                        <Button Element={Link} to={triggerHref} {...otherProps}>
                            {children}
                        </Button>
                    ) : (
                        <Button {...otherProps}>{children}</Button>
                    )}
                </VacancyActionLayout>
            </div>
        );
    }

    if (triggerType === TriggerType.Menu) {
        const otherProps = {
            'data-qa': `vacancy-action-${triggerName}`,
            onClick,
            ...targetProps,
        };
        return triggerHref ? (
            <MenuItem to={triggerHref} Element={Link} {...otherProps}>
                <VacancyActionLayout isMenu>
                    {children}
                    {icon}
                </VacancyActionLayout>
            </MenuItem>
        ) : (
            <MenuItem {...otherProps}>
                <VacancyActionLayout isMenu>
                    {children}
                    {icon}
                </VacancyActionLayout>
            </MenuItem>
        );
    }

    return null;
};

export default VacancyViewAction;
