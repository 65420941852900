import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { AstrostarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import fetchVacancyUpgrades from 'src/api/vacancyUpgrades/fetchVacancyUpgrades';
import {
    useClassificationChangesPage,
    EXPERIMENT_NAME_CLASSIFICATION_CHANGES,
    FromPages,
} from 'src/components/EmployerVacancies/hooks/useClassificationChangesPage';
import { useNotification } from 'src/components/Notifications/Provider';
import { ADD_MODAL, MODAL_UPGRADE } from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
import { useExperimentGroupCheckFunction } from 'src/hooks/useExperimentCheckFunction';

import vacancyActionErrorNotification from 'src/components/VacancyActions/VacancyActionError';
import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';
import { openBlankRisePage } from 'src/components/VacancyActions/risePageUtils';

const openModal = ({ dispatchModal, afterVacancyTypeHasBeenChanged, onClose, vacancyId, employerId, from }) => {
    Analytics.sendEvent('account', 'update-vacancy', from, vacancyId);

    dispatchModal({
        type: ADD_MODAL,
        payload: {
            modalType: MODAL_UPGRADE,
            data: { afterVacancyTypeHasBeenChanged, vacancyId, employerId, from },
        },
    });
    onClose?.();
};

const useHandleUpgrade = ({
    addNotification,
    afterVacancyTypeHasBeenChanged,
    fetchVacancyUpgrades,
    vacancyId,
    employerId,
    isLoading,
    setLoading,
    dispatchModal,
    onClose,
    from,
}) => {
    return useCallback(async () => {
        if (isLoading) {
            return;
        }

        setLoading(true);

        try {
            await fetchVacancyUpgrades({ params: { employerId, vacancyId } });
        } catch {
            addNotification(vacancyActionErrorNotification);
            return;
        } finally {
            setLoading(false);
        }

        openModal({ dispatchModal, afterVacancyTypeHasBeenChanged, onClose, vacancyId, employerId, from });
    }, [
        afterVacancyTypeHasBeenChanged,
        isLoading,
        setLoading,
        dispatchModal,
        onClose,
        vacancyId,
        employerId,
        from,
        fetchVacancyUpgrades,
        addNotification,
    ]);
};

const UpgradeAction = ({
    Component,
    trls,
    triggerType,
    permissions,
    additionalAnalyticsParams,
    text,
    actionType,
    displayInOwnColumn,
    // tempexp_32484_start
    vacancyId,
    from,
    // tempexp_32484_end
    ...triggerProps
}) => {
    const [isLoading, setLoading] = useState(false);
    const [modalShown, setModalShown] = useState(false);
    const { addNotification } = useNotification();

    const triggerName = 'upgrade';
    const iconProps = displayInOwnColumn
        ? undefined
        : getMenuButtonIconProps({
              triggerType,
              triggerName,
              icon: <AstrostarOutlinedSize24 />,
          });

    const handleTrigger = useHandleUpgrade({
        ...triggerProps,
        vacancyId,
        from,
        addNotification,
        isLoading,
        setLoading,
    });
    // tempexp_32484_start
    const { isExpClassificationChangesPage } = useClassificationChangesPage();
    const getExperimentGroup = useExperimentGroupCheckFunction();

    const handleTriggerExp = useCallback(() => {
        openBlankRisePage(
            [vacancyId],
            'classificationChanges',
            from === FromPages.MyVacancies,
            from === FromPages.Vacancy ? FromPages.Vacancy : undefined
        );
    }, [from, vacancyId]);

    const handleTriggerCommon = useCallback(() => {
        getExperimentGroup(EXPERIMENT_NAME_CLASSIFICATION_CHANGES);
        if (isExpClassificationChangesPage) {
            handleTriggerExp();
        } else {
            handleTrigger();
        }
    }, [getExperimentGroup, handleTrigger, handleTriggerExp, isExpClassificationChangesPage]);
    // tempexp_32484_end

    useEffect(() => {
        if (window.location.hash === '#upgrade' && !modalShown) {
            setModalShown(true);
            handleTrigger();
        }
    }, [handleTrigger, modalShown, setModalShown]);

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            // tempexp_32484_next_line
            onTrigger={handleTriggerCommon}
            isLoading={isLoading}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            additionalAnalyticsParams={additionalAnalyticsParams}
            displayInOwnColumn={displayInOwnColumn}
            actionType={actionType}
            {...iconProps}
        >
            {text || trls[UpgradeAction.trls.improve]}
        </Component>
    );
};

UpgradeAction.trls = {
    info: 'vacancy.update.text',
    upgradeTitle: 'vacancy.upgrade.popup.title',
    fail: 'employer.service.message.fail',
    cancel: 'employer.myVacancyes.move.cancel',
    improve: 'vacancy.tools.improve',
};

UpgradeAction.propTypes = {
    Component: PropTypes.elementType.isRequired,
    vacancyId: PropTypes.number,
    displayInOwnColumn: PropTypes.bool,
    triggerType: PropTypes.string,
    onClose: PropTypes.func,
    dispatchModal: PropTypes.func,
    permissions: PropTypes.array,
    fetchVacancyUpgrades: PropTypes.func,
    actionType: PropTypes.string,
    additionalAnalyticsParams: PropTypes.object,
    trls: PropTypes.object,
    from: PropTypes.string,
    text: PropTypes.string,
};

export default connect(
    ({ permissions }) => ({
        permissions,
    }),
    { fetchVacancyUpgrades }
)(translation(UpgradeAction));
