import { FC } from 'react';

import Compensation from 'src/components/Compensation';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

interface SuitableVacancyCompensationProps {
    compensation?: VacancySearchItem['compensation'];
}

const SuitableVacancyCompensation: FC<SuitableVacancyCompensationProps> = ({ compensation }) => {
    if (!compensation) {
        return null;
    }

    return (
        <div className="vacancy-of-the-day__salary">
            <Compensation {...compensation} />
        </div>
    );
};

export default SuitableVacancyCompensation;
