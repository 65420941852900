import React, { useContext, useEffect, useRef, FC } from 'react';
import { select } from 'd3-selection';

import ChartContext from 'src/pages/VacancyView/components/VacancyStatTab/Chart/LineChart/ChartContext';
import Color from 'src/pages/VacancyView/components/VacancyStatTab/Chart/color';

import RectangleChartIcon from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/RectangleChartIcon';
import { RectangleChartPoint } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/types';
import { getRectWidth } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/RectangleChart/utils';

const RECTANGLE_CHART_HEIGHT = 8;

interface RectangleChartProps {
    data: RectangleChartPoint[];
    radius?: number;
    background?: Color;
}

const RectangleChart: FC<RectangleChartProps> = ({ data, radius = 4, background }) => {
    const { xAxis } = useContext(ChartContext).chartData;
    const { width: svgContainerWidth, height: svgContainerHeight } = useContext(ChartContext);

    const rectangleChartRef = useRef<SVGGElement | null>(null);
    const [, xMax] = xAxis.range();

    useEffect(() => {
        if (!rectangleChartRef.current) {
            return;
        }

        const rect = select(rectangleChartRef.current).selectAll('rect').data(data).enter();

        if (background) {
            select(rectangleChartRef.current)
                .append('rect')
                .attr('width', svgContainerWidth)
                .attr('height', svgContainerHeight)
                .attr('rx', radius)
                .attr('fill', background);
        }

        rect.append('rect')
            .attr('x', (d) => xAxis(d.startDate))
            .attr('y', svgContainerHeight - RECTANGLE_CHART_HEIGHT)
            .attr('width', (d) => getRectWidth(d, xAxis))
            .attr('height', RECTANGLE_CHART_HEIGHT)
            .attr('rx', radius)
            .attr('fill', (d) => d.color);

        // eslint-disable-next-line consistent-return
        return () => {
            ['rect', 'circle', 'path'].forEach((el) => rect.selectAll(el).remove());
        };
    }, [radius, xAxis, svgContainerHeight, svgContainerWidth, data, xMax, background]);

    return <g ref={rectangleChartRef} />;
};

export { RectangleChartIcon };

export default RectangleChart;
