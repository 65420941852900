import { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column/index';
import VSpacing from 'bloko/blocks/vSpacing';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import bannerPropType from 'src/components/Banner/BannerPropType';
import Clickme from 'src/components/Clickme';
import { useSelector } from 'src/hooks/useSelector';

import { useTrackBannersClickEvent } from 'src/components/VacancyView/useBannersAnalytics';

const VacancySidebarBanners = ({ rightColumnBanners, rightColumnClickmeBanners, adsVacancyParams }) => {
    const bannersBatchUrl = useSelector((state) => state.bannersBatchUrl);
    const containerRef = useRef(null);
    useTrackBannersClickEvent({ containerRef });

    return (
        <div ref={containerRef}>
            {!!bannersBatchUrl && (
                <>
                    {rightColumnBanners?.length > 0 && (
                        <Column xs="0" s="0" m="4" l="4">
                            {rightColumnBanners.map((banner) => (
                                <AdsExternalBanner {...banner} key={banner.id} bannerAdTargeting={adsVacancyParams} />
                            ))}
                            <VSpacing base={6} />
                        </Column>
                    )}
                    {rightColumnClickmeBanners?.length > 0 && (
                        <Column xs="0" s="0" m="4" l="4">
                            <div className="vacancy-sidebar-clickme-wrapper">
                                <Clickme banners={rightColumnClickmeBanners} />
                            </div>
                            <VSpacing base={6} />
                        </Column>
                    )}
                </>
            )}
        </div>
    );
};

VacancySidebarBanners.propTypes = {
    rightColumnBanners: PropTypes.arrayOf(bannerPropType),
    rightColumnClickmeBanners: PropTypes.arrayOf(bannerPropType),
    adsVacancyParams: PropTypes.object,
};

export default connect(({ banners, adsVacancyParams }) => ({
    rightColumnBanners: banners['right-column'],
    rightColumnClickmeBanners: banners['right-column-clickme'],
    adsVacancyParams,
}))(VacancySidebarBanners);
