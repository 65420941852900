import { ChangeEvent, useState } from 'react';

import { CheckableChip, ChipsContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { Variant } from 'src/pages/VacancyView/components/VacancyStatTab/FeedbackCard/constant';

const TrlKeys = {
    [Variant.CostOfResponseAndSelection]: 'employer.vacancy.tab.stat.feedback.variant.COST_OF_RESPONSE_AND_SELECTION',
    [Variant.SalariesByGradeAndExperience]:
        'employer.vacancy.tab.stat.feedback.variant.SALARIES_BY_GRADE_AND_EXPERIENCE',
    [Variant.PortraitOfApplicant]: 'employer.vacancy.tab.stat.feedback.variant.PORTRAIT_OF_APPLICANT',
    [Variant.PopularTypeOfEmployment]: 'employer.vacancy.tab.stat.feedback.variant.POPULAR_TYPE_OF_EMPLOYMENT',
    [Variant.HowCompetitorsProgress]: 'employer.vacancy.tab.stat.feedback.variant.HOW_COMPETITORS_PROGRESS',
    [Variant.PopularWorkSchedule]: 'employer.vacancy.tab.stat.feedback.variant.POPULAR_WORK_SCHEDULE',
};

interface VariantChipChooserProps {
    onSelect: (reasons: Variant[]) => void;
}

const VariantChipChooser: TranslatedComponent<VariantChipChooserProps> = ({ trls, onSelect }) => {
    const [selectedReasons, setSelectedVariant] = useState<Variant[]>([]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newVariant = event.target.value as Variant;

        setSelectedVariant((prev) => {
            const newState = prev.includes(newVariant)
                ? prev.filter((reason) => reason !== newVariant)
                : [...prev, newVariant];
            onSelect(newState);
            return newState;
        });
    };

    return (
        <ChipsContainer>
            {Object.values(Variant).map((variant) => (
                <CheckableChip
                    key={variant}
                    type="checkbox"
                    name="feedback-modal-variant"
                    value={variant}
                    onChange={onChange}
                    checked={selectedReasons.includes(variant)}
                >
                    {trls[TrlKeys[variant]]}
                </CheckableChip>
            ))}
        </ChipsContainer>
    );
};

export default translation(VariantChipChooser);
