import { Dispatch, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { HSpacing } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ADD_MODAL, MODAL_VACANCY_ADVICE } from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyAdviceWithStatistics } from 'src/models/vacancy/vacancyAdvices.types';

import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';

import styles from './vacancy-important-banners.less';

const TrlKeys = {
    adviceCounter: {
        one: 'employer.myVacancies.advice.count.one',
        some: 'employer.myVacancies.advice.count.some',
        many: 'employer.myVacancies.advice.count.many',
    },
    adviceTitle: 'employer.vacancy.VacancyImportantBanners.advice',
};

const setPreparedAdviceWithStatistics = makeSetStoreField('preparedAdviceWithStatistics');

interface AdviceBannerProps {
    vacancyId: string;
    vacancyName: string;
    vacancyAreaName: string;
    dispatchModal: Dispatch<AnyAction>;
}

const AdviceBanner: TranslatedComponent<AdviceBannerProps> = ({
    dispatchModal,
    vacancyId,
    vacancyName,
    vacancyAreaName,
    trls,
}) => {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const canvacVacancySalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const compensation = useSelector((state) => state.vacancyView.compensation);
    const adviceWithStatistics = useSelector((state) => state.preparedAdviceWithStatistics);

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'important_banner_advice',
                vacancyId,
            });
        }
    }, [vacancyId]);

    if (!canvacVacancySalaryData || !canvacVacancySalaryData.marketRange || !adviceWithStatistics.length) {
        return null;
    }

    const { marketRange, salaryRange, numberOfSimilarVacancies } = canvacVacancySalaryData;

    const setAdvices = (advices: VacancyAdviceWithStatistics[]) => {
        dispatch(setPreparedAdviceWithStatistics(advices));
    };

    const openModal = () => {
        Analytics.sendHHEventButtonClick('important_banner_advice_button', {
            vacancyId,
        });

        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType: MODAL_VACANCY_ADVICE,
                data: {
                    vacancyId,
                    vacancyName,
                    vacancyAreaName,
                    adviceWithStatistics,
                    setAdvices,
                    compensation,
                    marketRange,
                    salaryRange,
                    numberOfSimilarVacancies,
                },
            },
        });
    };

    return (
        <BannerWrapper dataQa="vacancy-important-banner-advice" onClick={openModal}>
            <div className={styles.adviceBanner} ref={ref}>
                <AstrostarOutlinedSize16 initial="special" />
                <HSpacing default={8} />
                <span>
                    {trls[TrlKeys.adviceTitle]}
                    {': '}
                    <Conversion
                        value={adviceWithStatistics.length}
                        one={trls[TrlKeys.adviceCounter.one]}
                        some={trls[TrlKeys.adviceCounter.some]}
                        many={trls[TrlKeys.adviceCounter.many]}
                    />
                </span>
            </div>
        </BannerWrapper>
    );
};

export default translation(AdviceBanner);
