import { useDispatch } from 'react-redux';

import { Button, Alert, useBreakpoint } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize16, ExclamationTriangleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { vacancyDeleteHiringManagerAction } from 'src/models/vacancyView';
import fetcher from 'src/utils/fetcher';

const DELETE_HIRING_MANAGER_URL = '/shards/employer/hiring_managers/unshare_vacancy';

declare global {
    interface FetcherDeleteApi {
        [DELETE_HIRING_MANAGER_URL]: {
            queryParams: { vacancyId: string; managerId: string };
            response: void;
        };
    }
}

interface DeleteHiringManagerActionProps {
    vacancyId: string;
    managerId: string;
}

const TrlKeys = {
    triggerText: 'employer.vacancy.managers.deleteHiringManager.trigger.text',
    alertTitle: 'employer.vacancy.managers.deleteHiringManager.alert.title',
    alertDescription: 'employer.vacancy.managers.deleteHiringManager.alert.description',
    alertConfirmButton: 'employer.vacancy.managers.deleteHiringManager.alert.button.confirm',
    alertCancelButton: 'employer.vacancy.managers.deleteHiringManager.alert.button.cancel',
};

const DeleteHiringManagerAction: TranslatedComponent<DeleteHiringManagerActionProps> = ({
    trls,
    vacancyId,
    managerId,
}) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const showNotification = useHiringManagerNotification();
    const [isShowAlert, showAlert, hideAlert] = useOnOffState(false);
    const [isLoading, startLoading, finishLoading] = useOnOffState(false);

    const handleDeleteHiringManager = async () => {
        startLoading();
        try {
            await fetcher.delete(DELETE_HIRING_MANAGER_URL, { params: { vacancyId, managerId } });
        } catch (_) {
            showNotification?.(undefined, HiringManagerNotificationType.DefaultError);
            finishLoading();
            return;
        }

        dispatch(vacancyDeleteHiringManagerAction({ managerId }));
        hideAlert();
        finishLoading();
    };

    return (
        <>
            <Button
                mode="tertiary"
                size="small"
                icon={<CrossOutlinedSize16 />}
                onClick={showAlert}
                hideLabel={isMobile}
                aria-label={isMobile ? trls[TrlKeys.triggerText] : ''}
            >
                {trls[TrlKeys.triggerText]}
            </Button>
            <Alert
                visible={isShowAlert}
                icon={<ExclamationTriangleOutlinedSize24 />}
                iconStyle="warning"
                title={trls[TrlKeys.alertTitle]}
                description={trls[TrlKeys.alertDescription]}
                layout="vertical"
                onClose={hideAlert}
                buttons={
                    <>
                        <Button mode="primary" style="accent" onClick={handleDeleteHiringManager} loading={isLoading}>
                            {trls[TrlKeys.alertConfirmButton]}
                        </Button>
                        <Button mode="tertiary" style="accent" onClick={hideAlert}>
                            {trls[TrlKeys.alertCancelButton]}
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default translation(DeleteHiringManagerAction);
