import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import getConversionType from 'bloko/common/getConversionType';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Timeline from 'src/components/Timeline';
import VacancySuitableResumesBanner from 'src/components/VacancySuitableResumesBanner';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    aboutLink: 'employer.vacancy.history.mailings.aboutLink',
    title: {
        changes: 'employer.vacancy.history.changes.title',
        mailings: 'employer.vacancy.history.mailings.title',
    },
    count: {
        one: 'employer.vacancy.changesHistory.recipients.one',
        some: 'employer.vacancy.changesHistory.recipients.many',
        many: 'employer.vacancy.changesHistory.recipients.some',
    },
};

const VacancyHistoryTab: TranslatedComponent = ({ trls }) => {
    const systemInfo = useSelector((state) => state.vacancyView.systemInfo);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const { isM, isS, isXS } = useBreakpoint();
    const isVacancySuitableResumesExp = useExperiment('vacancy_block_suitable_resumes');
    const { isBranded } = useBranding();

    const history = systemInfo?.history ?? [];
    const mailings = systemInfo?.mailings ?? [];

    const preparedHistoryData = history.map((record, ind) => ({
        id: `${record.time}-${ind}`,
        dateTime: record.time,
        title: `${record.type}${record?.comment ? NON_BREAKING_SPACE + record?.comment : ''}`,
        text: record.user,
    }));

    const preparedMailingData = mailings.map((record, ind) => {
        const conversionCount = getConversionType({
            one: trls[TrlKeys.count.one],
            some: trls[TrlKeys.count.some],
            many: trls[TrlKeys.count.many],
            value: record.count,
        });

        return {
            id: `${record.date}-${ind}`,
            dateTime: record.date,
            title: `${record.count}${NON_BREAKING_SPACE}${conversionCount}`,
        };
    });

    const onShowMore = (eventName: string) => () => {
        Analytics.sendHHEventButtonClick(eventName, {
            vacancyId,
        });
    };

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                <Timeline
                    title={trls[TrlKeys.title.changes]}
                    data={preparedHistoryData}
                    showTime
                    onShowMoreCallback={onShowMore('vacancy_view_history_tab_show_more_items')}
                />
                <VSpacing default={24} />
                <Timeline
                    title={trls[TrlKeys.title.mailings]}
                    data={preparedMailingData}
                    onShowMoreCallback={onShowMore('vacancy_view_history_tab_show_more_items_mailings')}
                />
                {isVacancySuitableResumesExp && ((isM && isBranded) || isS || isXS) && (
                    <>
                        <VSpacing default={24} />
                        <VacancySuitableResumesBanner vacancyId={vacancyId as number} />
                    </>
                )}
            </Column>
        </ColumnsRow>
    );
};

export default translation(VacancyHistoryTab);
