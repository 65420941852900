import { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Config } from 'final-form';

import { Action, BottomSheet, BottomSheetFooter, Button, Modal, NavigationBar, ActionBar } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import VacancyPermissions from 'src/components/HiringManager/VacancyPermissions';
import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import { EditPermissionsFormData } from 'src/components/HiringManager/types';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';
import fetcher from 'src/utils/fetcher';

export interface EditPermissionsModalProps {
    visible: boolean;
    handleCloseModal: () => void;
    vacancyId: string;
    employerManagerId: string;
    permissions: VacancyPermission[];
    isMcpPermissions: boolean;
    onSuccessSubmit?: (newPermissions: VacancyPermission[]) => void;
}

const TrlKeys = {
    title: 'employer.vacancy.editHiringManagerPermissions.modal.title',
    description: 'employer.vacancy.editHiringManagerPermissions.modal.description',
    submitButton: 'form.submit.save',
    closeButton: 'close',
};

const FORM_ID = 'edit-hiring-manager-permissions-form';

const EditPermissionsModal: TranslatedComponent<EditPermissionsModalProps> = ({
    trls,
    visible,
    handleCloseModal,
    vacancyId,
    employerManagerId,
    permissions,
    isMcpPermissions,
    onSuccessSubmit,
}) => {
    const [isLoading, startLoading, finishLoading] = useOnOffState(false);
    const showNotification = useHiringManagerNotification();

    const handleSubmit = useCallback<Config<EditPermissionsFormData>['onSubmit']>(
        async (data) => {
            const { vacancyId, employerManagerId } = data;
            const permissions = Object.values(VacancyPermission).reduce<VacancyPermission[]>(
                (acc, permission) => (data[permission] ? [...acc, permission] : acc),
                []
            );

            startLoading();
            try {
                await fetcher.put('/shards/employer/hiring_managers/share_vacancy', [
                    { vacancyId, employerManagerId, permissions },
                ]);
            } catch (_) {
                showNotification?.(undefined, HiringManagerNotificationType.DefaultError);
                finishLoading();
                return;
            }

            onSuccessSubmit?.(permissions);
            finishLoading();
            handleCloseModal();
        },
        [finishLoading, handleCloseModal, onSuccessSubmit, showNotification, startLoading]
    );

    const initialValues: EditPermissionsFormData = useMemo(
        () => ({
            vacancyId,
            employerManagerId,
            ...Object.values(VacancyPermission).reduce<Partial<Record<VacancyPermission, boolean>>>(
                (acc, permission) => ({ ...acc, [permission]: permissions.includes(permission) }),
                {}
            ),
        }),
        [employerManagerId, permissions, vacancyId]
    );

    const title = trls[TrlKeys.title];
    const description = isMcpPermissions ? trls[TrlKeys.description] : undefined;

    const content = (
        <FinalForm<EditPermissionsFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subscription={{ values: true }}
        >
            {({ handleSubmit }) => (
                <Form id={FORM_ID} onSubmit={handleSubmit}>
                    <VacancyPermissions disabled={isMcpPermissions} />
                </Form>
            )}
        </FinalForm>
    );

    const submitButton = (
        <Button type="submit" form={FORM_ID} mode="primary" style="accent" loading={isLoading}>
            {trls[TrlKeys.submitButton]}
        </Button>
    );

    const closeButton = (
        <Button mode="primary" style="accent" onClick={handleCloseModal}>
            {trls[TrlKeys.closeButton]}
        </Button>
    );

    const displayedButton = isMcpPermissions ? closeButton : submitButton;

    return (
        <>
            <Modal
                title={title}
                titleSize="medium"
                titleDescription={description}
                titleDescriptionStyle="secondary"
                visible={visible}
                onClose={handleCloseModal}
                footer={<ActionBar primaryActions={displayedButton} />}
                actions={<Action mode="secondary" icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
            >
                {content}
            </Modal>
            <BottomSheet
                visible={visible}
                header={<NavigationBar title={title} subtitle={description} />}
                footer={<BottomSheetFooter>{displayedButton}</BottomSheetFooter>}
                onClose={handleCloseModal}
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default translation(EditPermissionsModal);
