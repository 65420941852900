import { FC } from 'react';
import { generatePath } from 'react-router';

import Analytics from '@hh.ru/analytics-js';
import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import paths from 'src/app/routePaths';
import SalaryInfo from 'src/components/SalaryInfo';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { CanvacSimilarVacancy } from 'src/models/canvacSimilarVacancies';
import { COMMA, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './similar-vacancy.less';

interface SimilarVacancyProps {
    vacancy: CanvacSimilarVacancy;
    noCompensationText: string;
    numInList: number;
}

const SimilarVacancy: FC<SimilarVacancyProps> = ({ vacancy, numInList, noCompensationText }) => {
    const { id, name, compensation, area, company } = vacancy;

    const similarVacancyCardRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_similar_vacancy_card',
        vacancyId: id,
        numInList,
    });

    const onLinkClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_similar_vacancy_card_link', {
            vacancyId: id,
            numInList,
        });
    };

    return (
        <Link
            to={generatePath(paths.vacancyView, { id })}
            className={styles.similarVacancy}
            target="_blank"
            onClick={onLinkClick}
            data-qa="tab-stat-similar-vacancy"
        >
            <Text Element="p" typography="label-3-regular" maxLines={2}>
                {name}
            </Text>
            <div ref={similarVacancyCardRef}>
                <Text Element="p" typography="label-3-regular" style="secondary" maxLines={2}>
                    <SalaryInfo
                        defaultValue={noCompensationText}
                        from={compensation?.from}
                        to={compensation?.to}
                        currency={compensation?.currencyCode}
                        noWrap
                    />
                </Text>
                <VSpacing default={8} />
                <Text Element="p" typography="label-3-regular" style="tertiary" maxLines={1}>
                    {area}
                    {COMMA}
                    {NON_BREAKING_SPACE}
                    {company}
                </Text>
            </div>
        </Link>
    );
};

export default SimilarVacancy;
