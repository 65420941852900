import { Dispatch, useRef } from 'react';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { Card, HSpacing, TooltipHover, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { BellOutlinedSize16, ExclamationCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CanvacBannerCase } from 'src/models/canvacVacancySalaryData';

import AdviceBanner from 'src/components/VacancyImportantBanners/AdviceBanner';
import BrandedSnippetBanner from 'src/components/VacancyImportantBanners/BrandedSnippetBanner';
import CanvacBanner from 'src/components/VacancyImportantBanners/CanvacBanner';
import ConversionBanner from 'src/components/VacancyImportantBanners/ConversionBanner';

import styles from './vacancy-important-banners.less';

interface VacancyImportantBannersProps {
    vacancyId: string;
    vacancyName: string;
    dispatchModal: Dispatch<AnyAction>;
    marginBottom?: boolean;
}

const TrlKeys = {
    title: 'employer.vacancy.VacancyImportantBanners.header',
    hint: 'employer.vacancy.VacancyImportantBanners.header.tip',
};

const VacancyImportantBanners: TranslatedComponent<VacancyImportantBannersProps> = ({
    vacancyId,
    vacancyName,
    dispatchModal,
    marginBottom = false,
    trls,
}) => {
    const canvacVacancySalaryData = useSelector(({ canvacVacancySalaryData }) => canvacVacancySalaryData);
    const conversion = useSelector(({ vacancyAnalyticsConversion }) => vacancyAnalyticsConversion);
    const isBrandSnippetInfoAllowed = useSelector((store) => store.isBrandSnippetInfoAllowed);
    const advice = useSelector((state) => state.preparedAdviceWithStatistics);
    const vacancyView = useSelector((state) => state.vacancyView);
    const activatorRef = useRef<HTMLDivElement>(null);
    if (!canvacVacancySalaryData?.competitionLevel && !advice.length && !conversion && !isBrandSnippetInfoAllowed) {
        return null;
    }

    return (
        <>
            <Card style="neutral" borderRadius={24} padding={12} stretched data-qa="vacancy-important-banners">
                <div className={styles.bannersHeader}>
                    <span className={styles.bannersHeaderText}>
                        <BellOutlinedSize16 initial="secondary" />
                        <HSpacing default={12} />
                        {trls[TrlKeys.title]}
                    </span>
                    <span>
                        <div
                            ref={activatorRef}
                            onMouseEnter={() => {
                                Analytics.sendHHEvent('important_banner_header_info_shown', {
                                    vacancyId,
                                });
                            }}
                        >
                            <ExclamationCircleOutlinedSize16 initial="secondary" />
                        </div>
                        <TooltipHover placement="top-left" activatorRef={activatorRef}>
                            {trls[TrlKeys.hint]}
                        </TooltipHover>
                    </span>
                </div>
                <VSpacing default={10} />
                <VSpacingContainer default={8}>
                    <AdviceBanner
                        dispatchModal={dispatchModal}
                        vacancyId={vacancyId}
                        vacancyName={vacancyName}
                        vacancyAreaName={vacancyView?.area?.name || ''}
                    />
                    <ConversionBanner vacancyId={vacancyId} />
                    <CanvacBanner vacancyId={vacancyId} canvacBannerCase={CanvacBannerCase.Salary} />
                    <CanvacBanner vacancyId={vacancyId} canvacBannerCase={CanvacBannerCase.CompetitionLevel} />
                    <BrandedSnippetBanner />
                </VSpacingContainer>
            </Card>
            {marginBottom && <VSpacing default={12} />}
        </>
    );
};

export default translation(VacancyImportantBanners);
