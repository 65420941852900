import { FC } from 'react';

import vacancySuitableItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_suitable_item_button_click';
import { Card, Link as MagritteLink, Text as MagritteText, VSpacingContainer } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyCompany, VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

import Company from 'src/components/SuitableVacancy/Company';
import Compensation from 'src/components/SuitableVacancy/Compensation';

interface SuitableVacancyProps {
    vacancyId: VacancySearchItem['vacancyId'];
    vacancyName: VacancySearchItem['name'];
    companyId: VacancyCompany['id'];
    companyVisibleName: VacancyCompany['visibleName'];
    companyDepartment: VacancyCompany['department'];
    companyLogo?: string;
    area: VacancySearchItem['area'];
    compensation: VacancySearchItem['compensation'];
    links: VacancySearchItem['links'];
    isMagritteVacancy?: boolean;
}

const SuitableVacancy: FC<SuitableVacancyProps> = ({
    vacancyId,
    vacancyName,
    compensation,
    companyId,
    companyVisibleName,
    companyDepartment,
    companyLogo,
    area,
    links,
    isMagritteVacancy,
}) => {
    const isAnonymous = useSelector((state) => state.userType) === UserType.Anonymous;
    const url = urlParser(links.desktop);
    const hhtmFromLabel = isAnonymous ? 'similar_vacancies_sidebar' : 'suitable_vacancies_sidebar';
    const vacancyViewId = useSelector((state) => state.vacancyView?.vacancyId ?? 0);

    const onNameClick = () => {
        vacancySuitableItemButtonClick({
            hhtmSourceLabel: 'vacancy_side_block',
            vacancyId: vacancyViewId,
            vacancyTargetId: vacancyId,
        });
    };

    const company = (
        <Company
            id={companyId}
            visibleName={companyVisibleName}
            department={companyDepartment}
            logo={companyLogo}
            area={area}
            isMagritteVacancy={isMagritteVacancy}
        />
    );

    if (isMagritteVacancy) {
        return (
            <Card stretched showBorder padding={24} borderRadius={24}>
                <VSpacingContainer default={24}>
                    <MagritteLink
                        style="neutral"
                        Element={Link}
                        to={url.href}
                        additionalQueryParams={{ hhtmFromLabel }}
                        target="_blank"
                        onClick={onNameClick}
                    >
                        <VSpacingContainer default={8}>
                            <MagritteText typography="label-2-regular">{vacancyName}</MagritteText>
                            <MagritteText typography="subtitle-1-semibold">
                                <Compensation compensation={compensation} />
                            </MagritteText>
                        </VSpacingContainer>
                    </MagritteLink>
                    {company}
                </VSpacingContainer>
            </Card>
        );
    }

    return (
        <div className="recommended-vacancies__item recommended-vacancies__item_full">
            <Text size={TextSize.Large}>
                <div>
                    <BlokoLink
                        kind={LinkKind.Tertiary}
                        Element={Link}
                        to={url.href}
                        additionalQueryParams={{ hhtmFromLabel }}
                        target="_blank"
                        onClick={onNameClick}
                    >
                        <span className="vacancy-of-the-day__title">{vacancyName}</span>
                        <Compensation compensation={compensation} />
                    </BlokoLink>
                    <span className={'vacancy-of-the-day-company-redesigned'}>{company}</span>
                </div>
            </Text>
        </div>
    );
};

export default SuitableVacancy;
