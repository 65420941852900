import { RefObject } from 'react';

import Analytics, { VendorAnalytics } from '@hh.ru/analytics-js';

import { TriggerType } from 'src/components/ControlsTrigger';

const EVENT_CATEGORY = 'employer-vacancy';

export const sendEventShownButton = (
    buttonsContainerRef: RefObject<HTMLDivElement>,
    buttonRef: RefObject<HTMLDivElement>,
    buttonName: string,
    vacancyId: number
): void => {
    if (buttonsContainerRef?.current && buttonRef?.current) {
        // Проверка ниже реализована для того, чтобы не отсылались события показа на элементах buttonRef,
        // которые могут быть скрыты из-за того, что ширина контейнера не вмещает все кнопки в один ряд,
        // а второй ряд скрывается, потому что у контейнера buttonsContainerRef (класс vacancy-actions__main)
        // присутствует overflow: hidden; с заданной высотой.
        // Второй ряд находится в верстке и имеет координаты, попадающие во вьюпорт,
        // поэтому обычная проверка в sendEventElementShown с elementSpy не срабатывает
        if (
            buttonsContainerRef.current.getBoundingClientRect().bottom > buttonRef.current.getBoundingClientRect().top
        ) {
            Analytics.sendEventElementShown(
                buttonRef.current,
                EVENT_CATEGORY,
                'shown-vacancy-action-button',
                buttonName
            );
            Analytics.sendHHEventElementShown(buttonRef.current, {
                name: 'employer_vacancy_action_button',
                label: buttonName,
                vacancyId,
            });
        }
    }
};

export const sendEventShownMenuItems = (items: string[]): void =>
    items.forEach((item) => {
        Analytics.sendEvent(EVENT_CATEGORY, 'shown-vacancy-action-menu', item);
        Analytics.sendHHEvent('element_shown', {
            name: 'employer_vacancy_action_menu',
            label: item,
        });
    });

export const sendEventClickAction = (
    triggerType: TriggerType,
    triggerName: string,
    vacancyId: number,
    toStorage = false
): void => {
    const event = `click-vacancy-action-${triggerType}`;
    if (toStorage) {
        VendorAnalytics.eventToStorage(EVENT_CATEGORY, event, triggerName);
    } else {
        Analytics.sendEvent(EVENT_CATEGORY, event, triggerName);
    }
    Analytics.sendHHEventButtonClick(`employer_vacancy_action_${triggerType}`, {
        label: triggerName,
        vacancyId,
    });
};
