import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useBreakpoint, Text, VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import VacancySuitableResumesBanner from 'src/components/VacancySuitableResumesBanner';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { CanvacSimilarVacancy, setCanvacSimilarVacancies } from 'src/models/canvacSimilarVacancies';
import { AnalyzeResultType } from 'src/models/canvacVacancySalaryData';
import { LoadingState } from 'src/models/loadingState';
import { VacancyAdvice } from 'src/models/vacancy/vacancyAdvices.types';
import { VacancyConversionStatistics, setVacancyConversionStatistics } from 'src/models/vacancyConversionStatistics';
import fetcher from 'src/utils/fetcher';

import Chart from 'src/pages/VacancyView/components/VacancyStatTab/Chart';
import { prepareReportData } from 'src/pages/VacancyView/components/VacancyStatTab/Chart/utils';
import CompetitionCard from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard';
import CompetitorCard from 'src/pages/VacancyView/components/VacancyStatTab/CompetitorCard';
import FeedbackCard from 'src/pages/VacancyView/components/VacancyStatTab/FeedbackCard';
import LoadingResult from 'src/pages/VacancyView/components/VacancyStatTab/LoadingResult';
import MetricCard from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard';
import NoFoundData from 'src/pages/VacancyView/components/VacancyStatTab/NoFoundData';
import SalaryRangeCard from 'src/pages/VacancyView/components/VacancyStatTab/SalaryRangeCard';

const GET_VACANCY_ANALYTICS_URL = '/shards/vacancy/get_vacancy_analytics';

const setVacancyAdviceWithStatistics = makeSetStoreField('vacancyAdvices');

declare global {
    interface FetcherGetApi {
        [GET_VACANCY_ANALYTICS_URL]: {
            queryParams: {
                id?: number[];
                vacancyId: number;
            };
            response: {
                conversion: VacancyConversionStatistics;
                vacancies: CanvacSimilarVacancy[];
                advices: VacancyAdvice[];
            };
        };
    }
}

const TrlKeys = {
    analysisAndCompetitors: 'employer.vacancy.tab.stat.analysisAndCompetitors.title',
};

const VacancyStatTab: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const canvacSalaryData = useSelector((state) => state.canvacVacancySalaryData);

    const vacancies = useSelector((state) => state.canvacSimilarVacancies);
    const { loadingState, data } = useSelector((state) => state.vacancyConversionStatistics);
    const preparedChartData = prepareReportData(data);
    const isShow = preparedChartData && data;
    const isHHRu = useIsHhru();
    const isVacancyTabCompetitorsExp = useExperiment('vacancy_tab_competitors', isHHRu);
    const isVacancySuitableResumesExp = useExperiment('vacancy_block_suitable_resumes');
    const isShowAnalyticsInfo =
        isVacancyTabCompetitorsExp && [LoadingState.Success, LoadingState.Error].includes(loadingState);
    const { isM, isS, isXS } = useBreakpoint();
    const { isBranded } = useBranding();

    // TODO: пока не используем, после экспа вернём
    // useScrollToCard()

    const fetchVacancyAnalytics = useCallback(
        async (vacancyId: number, ids?: number[]) => {
            try {
                const response = await fetcher.get(GET_VACANCY_ANALYTICS_URL, {
                    params: { id: ids, vacancyId },
                });
                dispatch(
                    setVacancyConversionStatistics({
                        loadingState: LoadingState.Success,
                        data: response.conversion,
                    })
                );
                if (response?.vacancies) {
                    dispatch(setCanvacSimilarVacancies(response.vacancies));
                }
                if (response?.advices) {
                    dispatch(setVacancyAdviceWithStatistics(response.advices));
                }
            } catch (_) {
                dispatch(
                    setVacancyConversionStatistics({
                        loadingState: LoadingState.Error,
                        data: null,
                    })
                );
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (loadingState === LoadingState.None && vacancyId) {
            void fetchVacancyAnalytics(vacancyId, canvacSalaryData?.topSimilarVacancyIds);
        }
    }, [fetchVacancyAnalytics, loadingState, canvacSalaryData, vacancyId]);

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                <LoadingResult loadingState={loadingState} vacancyId={vacancyId} />
                {isShow && (
                    <VSpacingContainer default={16}>
                        <MetricCard data={data} />
                        <Chart data={preparedChartData} />
                    </VSpacingContainer>
                )}
                {isShowAnalyticsInfo && (
                    <>
                        <VSpacing default={40} />
                        <VSpacingContainer default={24} xs={16}>
                            <Text id={ANCHOR_TO_SECTION.AnalysisAndCompetitors} typography="title-5-semibold">
                                {trls[TrlKeys.analysisAndCompetitors]}
                            </Text>
                            {canvacSalaryData?.analyzeResultType === AnalyzeResultType.CalculatedFull ? (
                                <>
                                    <SalaryRangeCard />
                                    <CompetitionCard />
                                    {isShow && <CompetitorCard data={data} vacancies={vacancies} />}
                                    <FeedbackCard />
                                </>
                            ) : (
                                <NoFoundData
                                    analyzeResultType={canvacSalaryData?.analyzeResultType}
                                    vacancyId={vacancyId}
                                />
                            )}
                        </VSpacingContainer>
                    </>
                )}
                {isVacancySuitableResumesExp && (isS || isXS || (isM && isBranded)) && (
                    <>
                        <VSpacing default={40} />
                        <VacancySuitableResumesBanner vacancyId={vacancyId as number} />
                    </>
                )}
            </Column>
        </ColumnsRow>
    );
};

export default translation(VacancyStatTab);
