import {
    fetchVacancyUpgradesStart,
    fetchVacancyUpgradesSuccess,
    fetchVacancyUpgradesFailed,
} from 'src/models/employerVacancies/vacancyUpgrades';
import fetcher from 'src/utils/fetcher';

export default ({ params: { employerId, vacancyId } }) =>
    async (dispatch) => {
        dispatch(fetchVacancyUpgradesStart(vacancyId));

        try {
            const { backurl, suitableUpgrades } = await fetcher.get('/shards/employer/vacancies/suitableUpgrades', {
                params: {
                    employerId,
                    vacancyId,
                },
            });

            dispatch(fetchVacancyUpgradesSuccess({ id: vacancyId, data: { backurl, suitableUpgrades } }));
        } catch (err) {
            dispatch(fetchVacancyUpgradesFailed(vacancyId));

            throw err;
        }
    };
