import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link as MagritteLink } from '@hh.ru/magritte-ui';
import { ArrowSquareOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';

interface LinkToFullChartProps {
    vacancyId: number;
    text: string;
}

const LinkToFullChart: FC<LinkToFullChartProps> = ({ vacancyId, text }) => {
    const link = `/employer/statistics/vacancy_conversion/?vacancyId=${vacancyId}`;

    const onClickLink = () => {
        Analytics.sendHHEventButtonClick(`vacancy_view_stat_tab_open_full_chart`, {
            vacancyId,
        });
    };

    return (
        <MagritteLink
            Element={Link}
            to={link}
            target="_blank"
            onClick={onClickLink}
            iconLeft={ArrowSquareOutlinedSize16}
            typography="label-3-regular"
            data-qa="tab-stat-extended-chart"
        >
            {text}
        </MagritteLink>
    );
};

export default LinkToFullChart;
