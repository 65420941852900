import { FC, PropsWithChildren, RefCallback, useState } from 'react';

import { useResize } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const AnimatedHeightContainer: FC<PropsWithChildren> = ({ children }) => {
    const [containerHeight, setContainerHeight] = useState(0);

    useResize(() => {
        if (containerHeight) {
            setContainerHeight(0);
        }
    });

    const updateContainerHeight: RefCallback<HTMLDivElement> = (wrapper: HTMLDivElement | null) => {
        if (!wrapper) {
            return;
        }
        const { height } = wrapper.getBoundingClientRect();

        if (height !== containerHeight) {
            setContainerHeight(height);
        }
    };

    return (
        <div className={styles.container} style={{ height: containerHeight || 'auto' }}>
            <div ref={updateContainerHeight}>{children}</div>
        </div>
    );
};

export default AnimatedHeightContainer;
