import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import AddHiringManagerAction from 'src/pages/VacancyView/components/VacancyManagersTab/Actions/AddHiringManagerAction';
import DeleteHiringManagerAction from 'src/pages/VacancyView/components/VacancyManagersTab/Actions/DeleteHiringManagerAction';
import EditHiringManagerSettingsAction from 'src/pages/VacancyView/components/VacancyManagersTab/Actions/EditHiringManagerSettingsAction';
import ManagerList from 'src/pages/VacancyView/components/VacancyManagersTab/ManagerList';
import ManagerListItem from 'src/pages/VacancyView/components/VacancyManagersTab/ManagerListItem';

const TrlKeys = {
    label: 'employer.vacancy.hiring.managers.list.label',
    placeholder: 'employer.vacancy.hiring.managers.list.placeholder',
};

const HiringManagersList: TranslatedComponent = ({ trls }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId?.toString());
    const hiringManagers = useSelector((state) => state.vacancyView?.vacancyManagers?.hiringManagers);
    const isShowControls = useSelector(
        (state) => state.vacancyView?.vacancyManagers?.currentManagerPermissions.canManipulateHiringManagers
    );
    const isEmpty = hiringManagers?.length === 0;

    if (!hiringManagers || !vacancyId) {
        return null;
    }

    return (
        <ManagerList
            label={trls[TrlKeys.label]}
            actions={isShowControls && !isEmpty && <AddHiringManagerAction type="action" />}
        >
            {isEmpty && (
                <ManagerListItem style="tertiary" actions={isShowControls && <AddHiringManagerAction type="button" />}>
                    {trls[TrlKeys.placeholder]}
                </ManagerListItem>
            )}
            {hiringManagers.map((manager) => (
                <ManagerListItem
                    key={manager.id}
                    actions={
                        isShowControls && (
                            <>
                                <DeleteHiringManagerAction managerId={manager.id} vacancyId={vacancyId} />
                                <EditHiringManagerSettingsAction vacancyId={vacancyId} manager={manager} />
                            </>
                        )
                    }
                >
                    {manager.fullName}
                </ManagerListItem>
            ))}
        </ManagerList>
    );
};

export default translation(HiringManagersList);
