import { Fragment, FC, useCallback, useEffect, useReducer, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper, ColumnsRow } from 'bloko/blocks/column';

import SendFloodlightConversion from 'HH/SendFloodlightConversion';
import AuctionBanner from 'src/components/Auction/Banner';
import useAuctionCreationEnabled from 'src/components/Auction/hooks/useAuctionCreationEnabled';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import VacancyNotifications from 'src/components/Notifications/VacancyView';
import RelatedVacancies from 'src/components/RelatedVacancies/LazyWrapper';
import VacancyImportantBanners from 'src/components/VacancyImportantBanners';
import Modals, { modalReducer } from 'src/components/VacancyModals';
import { CLOSE_MODAL } from 'src/components/VacancyModals/constants';
import VacancySuitableResumesBanner from 'src/components/VacancySuitableResumesBanner';
import VacancyEmployerMainTitle from 'src/components/VacancyView/VacancyEmployerMainTitle';
import VacancyKeyInfo from 'src/components/VacancyView/VacancyKeyInfo';
import VacancySystemInfo from 'src/components/VacancyView/VacancySystemInfo';
import WantSame from 'src/components/VacancyView/WantSame';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { CountryId } from 'src/models/countryId';
import { UserType } from 'src/models/userType';
import { VacancyViewTab } from 'src/models/vacancyViewTab';

import VacancyBreadcrumbs from 'src/pages/VacancyView/components/VacancyBreadcrumbs';
import VacancyEmployerSidebar from 'src/pages/VacancyView/components/VacancyEmployerSidebar';
import VacancyHeader from 'src/pages/VacancyView/components/VacancyHeader';
import VacancyTabPaneContent from 'src/pages/VacancyView/components/VacancyTabPaneContent';
import VacancyTabs from 'src/pages/VacancyView/components/VacancyTabs';

const VacancyViewEmployer: FC = () => {
    const vacancyView = useSelector((state) => state.vacancyView);

    const { active: activeTab, tabs } = useSelector((state) => state.vacancyViewTab);
    const { isBranded, isConstructor, isChameleon } = useBranding();
    const isShowTabs = tabs.length > 1;

    const checkedVacanciesValue: Record<number, number[]> = {};
    if (vacancyView.managerId && vacancyView.vacancyId) {
        checkedVacanciesValue[vacancyView.managerId] = [vacancyView.vacancyId];
    }
    const checkedVacancies = useRef<Record<number, number[]>>(checkedVacanciesValue);
    const [modalData, dispatchModal] = useReducer(modalReducer, {});
    const handleCloseModal = useCallback(() => dispatchModal({ type: CLOSE_MODAL }), []);

    const isNewVacancy = useSelector(({ isNewVacancy }) => isNewVacancy);
    const userType = useSelector(({ userType }) => userType);
    const isTrustedEmployer = useSelector(
        (state) => state.employerInfo.isTrusted || state.vacancyView.company['@trusted']
    );
    const sendDV360Analytics = useDV360Analytics();
    const countryId = useSelector((state) => state.countryId);
    const addEventPixelsRu = useAddEventPixelsRu();

    const auctionCreationEnabled = useAuctionCreationEnabled();
    const isVacancySuitableResumesExp = useExperiment('vacancy_block_suitable_resumes');
    const { isM, isS, isXS } = useBreakpoint();
    const isShowSuitableResumeBanner =
        isVacancySuitableResumesExp && !isChameleon && (isM || isS || isXS) && vacancyView?.systemInfo;

    useEffect(() => {
        if (isNewVacancy && userType === UserType.Employer) {
            Analytics.sendEvent('employer', 'vacancy-publish');
            addEventPixelsRu('B2B_VACANCY_COMPLETE');
            sendDV360Analytics('VACANCY_SHOWN');

            if (countryId === CountryId.Kazakhstan) {
                SendFloodlightConversion({ type: 'hh_kz0', cat: 'new_v0' });
            }
        }
    }, [isNewVacancy, userType, countryId, addEventPixelsRu, sendDV360Analytics]);

    const vacancyLayoutHeader = (
        <>
            {vacancyView?.systemInfo && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VacancyHeader checkedVacancies={checkedVacancies} dispatchModal={dispatchModal} />
                        <VSpacing default={24} />
                    </Column>
                </ColumnsRow>
            )}
            <Column xs="4" s="8" m="0" l="0">
                <VacancyImportantBanners
                    vacancyId={String(vacancyView.vacancyId)}
                    vacancyName={vacancyView.name}
                    dispatchModal={dispatchModal}
                    marginBottom
                />
                {auctionCreationEnabled && <AuctionBanner />}
                <VSpacing default={24} />
            </Column>
            {isShowTabs && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VacancyTabs />
                        <VSpacing default={24} />
                    </Column>
                </ColumnsRow>
            )}
        </>
    );

    const backofficeRelatedVacancies = (
        <div className="vacancy-noprint">
            <Column xs="4" s="8" m="8" l="11">
                <RelatedVacancies isEmployerRedesign />
            </Column>
        </div>
    );

    const employerReviews = isTrustedEmployer && !isConstructor && (
        <Column xs="4" s="8" m="8" l="11">
            <EmployerReviewsWidgetBig />
        </Column>
    );

    const tabContent = (
        <VacancyTabPaneContent
            employerReviews={employerReviews}
            backofficeRelatedVacancies={backofficeRelatedVacancies}
        />
    );

    const titleAndInfo = (
        <>
            {vacancyView?.systemInfo && (
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VacancyEmployerMainTitle />
                        <VSpacing default={20} />
                        <VacancySystemInfo />
                        <VSpacing default={16} />
                        <VacancyKeyInfo />
                        <VSpacing default={20} />
                    </Column>
                </ColumnsRow>
            )}
        </>
    );

    return (
        <Fragment>
            <VacancyBreadcrumbs />
            <VSpacing default={2} />
            <VacancyNotifications />

            {isBranded ? (
                <>
                    <ColumnsWrapper>
                        <ColumnsRow>
                            <Column xs="4" s="8" m="9" l="11" container>
                                {titleAndInfo}
                                {vacancyLayoutHeader}
                                {tabContent}
                            </Column>
                            <Column xs="0" s="0" m="3" l="5">
                                <VacancyEmployerSidebar dispatchModal={dispatchModal} />
                            </Column>
                        </ColumnsRow>
                    </ColumnsWrapper>
                    <ColumnsWrapper>
                        <ColumnsRow>
                            <Column xs="4" s="8" m="0" l="0">
                                <VacancyEmployerSidebar dispatchModal={dispatchModal} />
                            </Column>
                            {activeTab === VacancyViewTab.Main && (
                                <>
                                    {employerReviews}

                                    {isShowSuitableResumeBanner && (
                                        <Column xs="4" s="8" m="8" l="10">
                                            <VacancySuitableResumesBanner vacancyId={vacancyView.vacancyId as number} />
                                        </Column>
                                    )}

                                    {(employerReviews || isShowSuitableResumeBanner) && (
                                        <VSpacing default={24} l={32} />
                                    )}
                                    {backofficeRelatedVacancies}
                                </>
                            )}
                        </ColumnsRow>
                        <WantSame keyPrefix="vacancyId" id={String(vacancyView.vacancyId)} />
                    </ColumnsWrapper>
                </>
            ) : (
                <ColumnsWrapper>
                    <ColumnsRow>
                        <Column xs="4" s="8" m="8" l="11" container>
                            {titleAndInfo}
                            {vacancyLayoutHeader}
                            {tabContent}
                        </Column>
                        <Column xs="4" s="8" m="4" l="5">
                            <VacancyEmployerSidebar dispatchModal={dispatchModal} />
                        </Column>
                    </ColumnsRow>
                </ColumnsWrapper>
            )}
            <Modals
                checkedVacancies={checkedVacancies.current}
                dispatchModal={dispatchModal}
                handleCloseModal={handleCloseModal}
                modalData={modalData}
            />
        </Fragment>
    );
};

export default VacancyViewEmployer;
