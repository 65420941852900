import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';

import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './vacancy-system-info.less';

export enum SystemInfoItemColor {
    Default = 'default',
    Warning = 'warning',
    Archive = 'archive',
}

interface SystemInfoItemProps {
    text: string;
    children: ReactNode;
    dataQa: string;
    color?: SystemInfoItemColor;
}

const colorsMapping = {
    [SystemInfoItemColor.Default]: 'secondary',
    [SystemInfoItemColor.Warning]: 'negative',
    [SystemInfoItemColor.Archive]: 'secondary',
} as const;

const SystemInfoItem: FC<SystemInfoItemProps> = ({ text, children, dataQa, color = SystemInfoItemColor.Default }) => (
    <div className={classnames(styles.vacancySystemInfoItem)} data-qa={dataQa}>
        <Text style={colorsMapping[color]} typography="label-3-regular">
            {text}
            {NON_BREAKING_SPACE}
            {children}
        </Text>
    </div>
);

export default SystemInfoItem;
