import { useState } from 'react';

import { Action, ActionBar, Button, Modal, Text, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import fetcher from 'src/utils/fetcher';

import callTrackingDisableSuccess, {
    callTrackingAlreadyDisabled,
} from 'src/pages/VacancyView/components/DisableCallTrackingNotification';

declare global {
    interface FetcherPostApi {
        '/shards/call_tracking/disable': {
            body: {
                vacancyId: number;
            };
            queryParams: void;
            response: {
                status: string;
            };
        };
    }
}

const TrlKeys = {
    disableCT: 'admin.callTracking.vacancy.disable',
    disable: 'admin.callTracking.vacancy.disable.submit',
    cancel: 'form.submit.cancel',
    disableCTWarning: 'admin.callTracking.vacancy.disable.submit.warning',
    defaultError: 'page.4xx.description',
};

const DisableCallTracking: TranslatedComponent<{ vacancyId: number }> = ({ trls, vacancyId }) => {
    const { addNotification } = useNotification();
    const userType = useSelector(({ userType }) => userType);
    const canDisableCallTracking = useSelector(({ vacancyView }) => vacancyView.canDisableCallTracking);
    const [isModalVisible, showModal, hideModal] = useOnOffState(false);
    const [isBlockButtonDisabled, setBlockButtonDisabled] = useState(false);
    const [callTrackingDisableError, setCallTrackingDisableError] = useState('');
    const [isCallTrackingDisableSending, setCallTrackingDisableSending] = useState(false);

    if (userType !== UserType.BackOffice || canDisableCallTracking === undefined) {
        return null;
    }

    const closeModal = () => {
        setCallTrackingDisableError('');
        hideModal();
    };

    const onDisableCallTrackingClick = async () => {
        if (isCallTrackingDisableSending) {
            return;
        }
        setCallTrackingDisableSending(true);
        setCallTrackingDisableError('');
        let result;
        try {
            result = await fetcher.post('/shards/call_tracking/disable', { vacancyId });
        } catch (_) {
            setCallTrackingDisableError(trls[TrlKeys.defaultError]);
            setCallTrackingDisableSending(false);
            return;
        }

        addNotification(
            result.data?.status === 'RULE_ALREADY_EXISTS' ? callTrackingAlreadyDisabled : callTrackingDisableSuccess
        );
        setCallTrackingDisableSending(false);
        setBlockButtonDisabled(true);
        hideModal();
    };

    return (
        <>
            <VSpacing default={12} />
            <Button
                disabled={!canDisableCallTracking || isBlockButtonDisabled}
                size="small"
                mode="primary"
                style="accent"
                onClick={showModal}
            >
                {trls[TrlKeys.disableCT]}
            </Button>
            <Modal
                visible={isModalVisible}
                onClose={closeModal}
                size="medium"
                title={trls[TrlKeys.disableCT]}
                actions={<Action mode="secondary" icon={CrossOutlinedSize24} onClick={closeModal} />}
                footer={
                    <ActionBar
                        primaryActions={
                            <Button onClick={onDisableCallTrackingClick} mode="primary" style="accent">
                                {trls[TrlKeys.disable]}
                            </Button>
                        }
                        secondaryActions={
                            <Button onClick={closeModal} mode="secondary" style="accent">
                                {trls[TrlKeys.cancel]}
                            </Button>
                        }
                    />
                }
            >
                <Text>
                    {format(trls[TrlKeys.disableCTWarning], {
                        '{0}': vacancyId,
                    })}
                </Text>
                {callTrackingDisableError && (
                    <>
                        <VSpacing default={12} />
                        <Text style="negative">{callTrackingDisableError}</Text>
                    </>
                )}
            </Modal>
        </>
    );
};

export default translation(DisableCallTracking);
