import { Breadcrumbs, BreadcrumbsItem, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsWrapper, ColumnsRow } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector, useSelectorNonNullable } from 'src/hooks/useSelector';

const TrlKeys = {
    myVacancies: 'employer.vacancies.breadcrumbs.myVacancies',
    myVacanciesArchive: 'employer.myVacancyes.archive',
    mainPage: 'employer.vacancies.breadcrumbs.mainPage',
};

const VacancyBreadcrumbs: TranslatedComponent = ({ trls }) => {
    const vacancyName = useSelectorNonNullable((state) => state.vacancyView.name);
    const company = useSelectorNonNullable((state) => state.vacancyView.company);
    const archived = useSelectorNonNullable((state) => state.vacancyView.status.archived);
    const employerId = useSelectorNonNullable((state) => state.employerId);
    const isHiringManager = useSelector((state) => state.isHiringManager);

    const firstBreadcrumb =
        Number(employerId) === company.id
            ? {
                  to: isHiringManager ? '/employer/hiring_manager/vacancies' : '/employer/vacancies',
                  children: trls[TrlKeys.myVacancies],
                  'aria-label': trls[TrlKeys.myVacancies],
                  Element: Link,
              }
            : {
                  to: '/',
                  children: trls[TrlKeys.mainPage],
                  'aria-label': trls[TrlKeys.mainPage],
                  Element: Link,
              };

    const lastBreadcrumb = {
        children: vacancyName,
        'aria-label': vacancyName,
    };

    const breadcrumbs = archived
        ? [
              firstBreadcrumb,
              {
                  to: '/employer/vacancies/archived',
                  children: trls[TrlKeys.myVacanciesArchive],
                  'aria-label': trls[TrlKeys.myVacanciesArchive],
                  Element: Link,
              },
              lastBreadcrumb,
          ]
        : [firstBreadcrumb, lastBreadcrumb];

    return (
        <ColumnsWrapper>
            <ColumnsRow>
                <Column xs="4" s="8" m="8" l="10">
                    <Breadcrumbs>
                        {breadcrumbs.map((item) => (
                            <BreadcrumbsItem key={item.children} {...item} />
                        ))}
                    </Breadcrumbs>
                    <VSpacing default={12} />
                </Column>
            </ColumnsRow>
        </ColumnsWrapper>
    );
};

export default translation(VacancyBreadcrumbs);
