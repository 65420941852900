import { FC } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import useAuctionCreationModal from 'src/components/Auction/hooks/useAuctionCreationModal';
import { ActionButtonProps } from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/types';

const UseSearchTopButton: FC<ActionButtonProps> = ({ vacancyId, isXS, children, sendEventClick }) => {
    const campaignCreationStatus = useSelectorNonNullable(({ auctionData }) => auctionData.campaignCreationStatus);
    const { showAuctionCreationModal, renderAuctionCreationModal } = useAuctionCreationModal({
        vacancyId: String(vacancyId),
        campaignCreationStatus,
    });

    const onButtonClick = () => {
        showAuctionCreationModal();
        sendEventClick('vacancy_view_stat_tab_advice_use_search_top');
    };

    return (
        <>
            <Button onClick={onButtonClick} mode="primary" style="accent" size={isXS ? 'small' : 'medium'}>
                {children}
            </Button>
            {renderAuctionCreationModal()}
        </>
    );
};

export default UseSearchTopButton;
