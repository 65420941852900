import { Ref, useState } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import vacancyBrandySnippetInfoButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/brandy_snippet/vacancy_brandy_snippet_info_button_click';
import vacancyBrandySnippetInfoElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/branding/brandy_snippet/vacancy_brandy_snippet_info_element_shown';
import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import formatDate from 'Modules/formatDate';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import InformationOverlay from 'src/components/VacancyImportantBanners/BrandedSnippetBanner/InformationOverlay';

const TrlKeys = {
    caption: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.caption',
    till: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.till',
    expired: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.expired',
    infotip: 'employer.vacancy.VacancyImportantBanners.brandedSnippet.infotip',
};

const WARN_BEFORE = 1000 * 60 * 60 * 24 * 7; // 7 days
const WARN_AFTER = 1000 * 60 * 60 * 24 * 7; // 7 days

const useSnippetInfo = (trls: Record<string, string>) => {
    const vacancyView = useSelector((state) => state.vacancyView);
    const isBrandSnippetInfoAllowed = useSelector((store) => store.isBrandSnippetInfoAllowed);

    // Сниппет не был применен
    if (!isBrandSnippetInfoAllowed || !vacancyView.brandSnippetExpirationTime) {
        return null;
    }

    const now = new Date().getTime();
    const expireTimestamp = vacancyView.brandSnippetExpirationTime * 1000;

    // Сниппет действует
    if (expireTimestamp > now) {
        const isExpiring = expireTimestamp < now + WARN_BEFORE;
        return (
            <Text
                Element="span"
                typography="label-3-regular"
                style={isExpiring ? 'negative' : undefined}
                data-qa={`vacancy-important-banner-branded-snippet-${isExpiring ? 'expiring' : 'applied'}`}
            >
                {trls[TrlKeys.till]}
                {NON_BREAKING_SPACE}
                <span data-qa="vacancy-important-banner-branded-snippet-date">
                    {formatDate(expireTimestamp, 'dd.MM')}
                </span>
            </Text>
        );
    }

    // Сниппет истек, прошло меньше недели
    if (expireTimestamp > now - WARN_AFTER) {
        return (
            <Text
                Element="span"
                style="negative"
                typography="label-3-regular"
                data-qa="vacancy-important-banner-branded-snippet-expired"
            >
                {trls[TrlKeys.expired]}
            </Text>
        );
    }

    return null;
};

const useAnalyticsParams = () => {
    const { vacancyId, brandSnippetExpirationTime } = useSelector((state) => state.vacancyView);

    if (!brandSnippetExpirationTime || !vacancyId) {
        return null;
    }

    return {
        vacancyId,
        expirationDate: formatDate(brandSnippetExpirationTime * 1000, 'yyyy-MM-dd'),
    };
};

const BrandedSnippetBanner: TranslatedComponent = ({ trls }) => {
    const info = useSnippetInfo(trls);
    const analyticsParams = useAnalyticsParams();
    const [showModal, setShowModal] = useState(false);

    if (!info || !analyticsParams) {
        return null;
    }

    return (
        <>
            <MagritteInfotip
                name="vacancyImportantBrandedSnippet"
                placement="left-center"
                showClose
                closeByClickOutside={false}
                visible
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                showBannerOnMobile={false}
                guardDistanceToActivator={30}
                render={({ activatorRef }) => (
                    <div ref={activatorRef as Ref<HTMLDivElement>}>
                        <ElementShownAnchor fn={vacancyBrandySnippetInfoElementShown} {...analyticsParams}>
                            <BannerWrapper
                                onClick={() => {
                                    setShowModal(true);
                                    vacancyBrandySnippetInfoButtonClick(analyticsParams);
                                }}
                                dataQa="vacancy-important-banner-branded-snippet"
                            >
                                <>
                                    <Text Element="span" typography="label-3-regular">
                                        {trls[TrlKeys.caption]}{' '}
                                    </Text>
                                    {info}
                                </>
                            </BannerWrapper>
                        </ElementShownAnchor>
                    </div>
                )}
            >
                <span data-qa="vacancy-important-banner-branded-snippet-infotip">{trls[TrlKeys.infotip]}</span>
            </MagritteInfotip>
            <InformationOverlay visible={showModal} onClose={() => setShowModal(false)} />
        </>
    );
};

export default translation(BrandedSnippetBanner);
