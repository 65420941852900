import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import VacancyBlockEmployer from 'src/components/VacancyView/VacancyBlock/Employer';
import VacancySidebarBanners from 'src/components/VacancyView/VacancySidebarBanners';
import { useSelector } from 'src/hooks/useSelector';

import RelatedVacanciesPreview from 'src/pages/VacancyView/components/RelatedVacanciesPreview';

const VacancySidebar = ({ fullListRef, vacancyBodyFooterNodeRef }) => {
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);

    return (
        <div className="noprint">
            <VacancyBlockEmployer vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            <div className={isMagritteVacancy ? undefined : 'vacancy-sidebar-column'}>
                <VacancySidebarBanners />
                <Column xs="0" s="0" m="0" l={isMagritteVacancy ? '6' : '5'}>
                    <RelatedVacanciesPreview fullListRef={fullListRef} isMagritteVacancy={isMagritteVacancy} />
                </Column>
            </div>
        </div>
    );
};

VacancySidebar.propTypes = {
    fullListRef: PropTypes.object,
    vacancyBodyFooterNodeRef: PropTypes.object,
};

export default VacancySidebar;
