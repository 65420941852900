import { ReactNode } from 'react';

import { Text, TextStyle, Divider, Card, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import translation from 'src/components/translation';
import { VacancyConversionStatistics } from 'src/models/vacancyConversionStatistics';
import LegendPoint from 'src/pages/VacancyView/components/VacancyStatTab/LegendPoint';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import formatToInt from 'src/utils/formatToInt';

import getComparisonQuality from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard/getComparisonQuality';
import { VacancyComparisonQuality } from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard/types';

import styles from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard/metricCard.less';

const TrlKeys = {
    viewInSearchCount: 'vacancy.stat.title.viewsInSearch',
    viewCount: 'vacancy.stat.title.views',
    responseCount: 'vacancy.stat.title.responses',
    conversion: 'vacancy.stat.title.conversion',
    conversionShort: 'vacancy.stat.title.conversion.short',
    conversionLow: 'vacancy.stat.title.conversion.low',
    conversionHigh: 'vacancy.stat.title.conversion.high',
    conversionLowShort: 'vacancy.stat.title.conversion.low.short',
    conversionHighShort: 'vacancy.stat.title.conversion.high.short',
};

export const COMPARISON_QUALITY_COLOR: Record<VacancyComparisonQuality, TextStyle> = {
    high: 'positive',
    low: 'negative',
    noCalc: 'tertiary',
};

const MetricColumnInfo = ({ title, text, dataQa }: { title: ReactNode; text: ReactNode; dataQa: string }) => (
    <div className={styles.metricColumnInfo}>
        <Text typography="label-3-regular" style="secondary">
            {title}
        </Text>
        <Text typography="label-3-regular" data-qa={dataQa}>
            {text}
        </Text>
    </div>
);

interface MetricCardProps {
    data: VacancyConversionStatistics;
}

const MetricCard: TranslatedComponent<MetricCardProps> = ({ data, trls }) => {
    const viewInSearchCount = formatToInt(data?.searchCount) || 0;
    const viewCount = formatToInt(data?.viewCount) || 0;
    const responseCount = formatToInt(data?.responseCount) || 0;
    const conversionPercent = NumberFormatter.format(String((data.viewToResponseConversion || 0) * 100), {
        decimalLength: 1,
    });
    const comparisonQuality = getComparisonQuality(data.viewToResponseConversionComparison);

    const { isXS, isMobile, isM } = useBreakpoint();
    const isShortText = isMobile || isM;

    const conversionText = isShortText ? trls[TrlKeys.conversionShort] : trls[TrlKeys.conversion];
    const conversionLowText = isShortText ? trls[TrlKeys.conversionLowShort] : trls[TrlKeys.conversionLow];
    const conversionHighText = isShortText ? trls[TrlKeys.conversionHighShort] : trls[TrlKeys.conversionHigh];

    const viewInSearch = (
        <MetricColumnInfo
            title={trls[TrlKeys.viewInSearchCount]}
            text={viewInSearchCount}
            dataQa="metric-info-view-in-search"
        />
    );

    const view = (
        <MetricColumnInfo
            title={
                <div className={styles.textWithLegend}>
                    <LegendPoint color="blue" />
                    {trls[TrlKeys.viewCount]}
                </div>
            }
            text={viewCount}
            dataQa="metric-info-view"
        />
    );

    const response = (
        <MetricColumnInfo
            title={
                <div className={styles.textWithLegend}>
                    <LegendPoint color="green" />
                    {trls[TrlKeys.responseCount]}
                </div>
            }
            text={responseCount}
            dataQa="metric-info-responseCount"
        />
    );

    const conversion = (
        <MetricColumnInfo
            title={conversionText}
            text={
                <span className={styles.metricInfoConversation}>
                    {comparisonQuality === 'noCalc' ? (
                        `${conversionPercent}%`
                    ) : (
                        <>
                            {conversionPercent}% — {NON_BREAKING_SPACE}
                            <Text typography="label-3-regular" style={COMPARISON_QUALITY_COLOR[comparisonQuality]}>
                                {comparisonQuality === 'high' ? conversionHighText : conversionLowText}
                            </Text>
                        </>
                    )}
                </span>
            }
            dataQa="metric-info-conversion"
        />
    );

    return (
        <Card showBorder stretched borderRadius={24} padding={isXS ? 16 : 24}>
            {isXS ? (
                <div className={styles.metricWrapMobile}>
                    <div className={styles.metricRowMobile}>
                        <div className={styles.metricItemMobile}>{viewInSearch}</div>
                        <Divider mode="vertical" length={40} />
                        <div className={styles.metricItemMobile}>{view}</div>
                    </div>
                    <Divider />
                    <div className={styles.metricRowMobile}>
                        <div className={styles.metricItemMobile}>{response}</div>
                        <Divider mode="vertical" length={40} />
                        <div className={styles.metricItemMobile}>{conversion}</div>
                    </div>
                </div>
            ) : (
                <div className={styles.metricWrap}>
                    {viewInSearch}
                    <Divider mode="vertical" length={24} />
                    {view}
                    <Divider mode="vertical" length={24} />
                    {response}
                    <Divider mode="vertical" length={24} />
                    {conversion}
                </div>
            )}
        </Card>
    );
};

export default translation(MetricCard);
