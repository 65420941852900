import { useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card, Text, useBreakpoint, VSpacing, VSpacingContainer, useResize } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import throttle from 'bloko/common/throttle';

import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';
import { CompetitionLevel } from 'src/models/canvacVacancySalaryData';
import { EM_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import CompetitionAdvice from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/CompetitionAdvice';
import CompetitionChart from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/CompetitionChart';
import CompetitionStatCard from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/CompetitionStatCard';
import { chartData } from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/constants';

import styles from './competition-card.less';

const TrlKeys = {
    tag: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.tag',
    levels: {
        LOW: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.low',
        MEDIUM: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.normal',
        HIGH: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.level.high',
    },
    levelDescriptions: {
        LOW: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.description.low',
        MEDIUM: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.description.medium',
        HIGH: 'statportal.competitorAnalysisForVacancy.applicants.competitionLevel.description.high',
    },
    hint: 'employer.vacancy.tab.stat.competition.hint',
};

const CompetitionCard: TranslatedComponent = ({ trls }) => {
    const { isXS, isGtM } = useBreakpoint();
    const canvacData = useSelector((state) => state.canvacVacancySalaryData);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const [ignoreXS, setIgnoreXS] = useState(false);

    const resizeHandler = () => {
        if (!document.body) {
            return;
        }
        const { width } = document.body.getBoundingClientRect();
        // Игнорируему xs разрешения до 540 px, чтобы отображать больше столбцов в графике
        setIgnoreXS(() => width >= 540 && width <= 699);
    };

    useResize(throttle(resizeHandler, 100));

    const competitionCardRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_сompetition_card',
        vacancyId,
    });

    if (!canvacData || canvacData.competitionLevel === CompetitionLevel.Undefined) {
        return null;
    }

    const { numberOfSimilarVacancies, applicantsPerVacancy, competitionLevel } = canvacData;

    const onChartClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_сompetition_card_chart', { vacancyId });
    };

    const countValuesInChart = (isXS || isGtM) && !ignoreXS ? 20 : 30;
    const chart = (
        <CompetitionChart
            data={chartData[competitionLevel].slice(0, countValuesInChart)}
            level={competitionLevel}
            onChartClick={onChartClick}
        />
    );

    return (
        <Card
            id={ANCHOR_TO_SECTION.CompetitionCard}
            stretched
            showBorder
            padding={12}
            borderRadius={24}
            ref={competitionCardRef}
        >
            <VSpacingContainer default={12}>
                <div className={styles.barChartHeader}>
                    <Text typography="label-2-regular">
                        {`${trls[TrlKeys.tag]}${NON_BREAKING_SPACE}${EM_DASH}${NON_BREAKING_SPACE}`}
                        <span className={styles[competitionLevel.toLowerCase()]} data-qa="tab-stat-competition-level">
                            {trls[TrlKeys.levels[competitionLevel]]}
                        </span>
                    </Text>
                    <Text typography="label-3-regular" style="secondary">
                        {trls[TrlKeys.levelDescriptions[competitionLevel]]}
                    </Text>
                </div>

                <Card stretched showBorder padding={16} borderRadius={12}>
                    <div className={styles.competitionStatsRow}>
                        {isGtM && chart}
                        <div className={styles.competitionStatsColumn}>
                            <CompetitionStatCard
                                applicantsPerVacancy={applicantsPerVacancy}
                                numberOfSimilarVacancies={numberOfSimilarVacancies}
                                ignoreXS={ignoreXS}
                            />
                            <VSpacing default={16} gteL={0} />
                            {!isGtM && chart}
                            <VSpacing default={12} xs={16} />
                            <Text typography="label-4-regular" style="secondary">
                                {trls[TrlKeys.hint]}
                            </Text>
                        </div>
                    </div>
                </Card>

                <CompetitionAdvice />
            </VSpacingContainer>
        </Card>
    );
};

export default translation(CompetitionCard);
