import { useRef, FC } from 'react';

import { TrashOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    delete: 'button.delete',
    confirm: 'vacancy.moderator.delete.confirm',
    error: 'vacancy.moderator.delete.error',
};

interface DeleteActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    vacancyId: number;
}

const DELETE_URL = '/admin/moderator/vacancy/delete';

interface FormValues {
    vacancyId: number;
}

declare global {
    interface FetcherPostApi {
        [DELETE_URL]: {
            queryParams: void;
            body: FormValues;
            response: never;
        };
    }
}

const DeleteAction: TranslatedComponent<DeleteActionProps> = ({ Component, triggerType, trls, vacancyId }) => {
    const disabled = useRef(false);

    const onTrigger = async () => {
        // eslint-disable-next-line no-alert
        if (disabled.current || !confirm(trls[TrlKeys.confirm])) {
            return;
        }
        disabled.current = true;
        const params = { vacancyId };
        try {
            await fetcher.postFormData(DELETE_URL, params);
        } catch (_) {
            alert(trls[TrlKeys.error]); // eslint-disable-line no-alert
            disabled.current = false;
        }
        window.location.reload();
    };

    const icon = <TrashOutlinedSize24 />;

    return (
        <Component isPermitted onTrigger={onTrigger} triggerName="delete" triggerType={triggerType} icon={icon}>
            {trls[TrlKeys.delete]}
        </Component>
    );
};

export default translation(DeleteAction);
