import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import translation from 'src/components/translation';
import { VacancyAnalyticsConversion } from 'src/models/vacancyAnalyticsConversion';
import formatToInt from 'src/utils/formatToInt';

import ColumnBox from 'src/components/VacancyConversionInfo/ColumnBox';

import styles from './vacancy-conversion-info.less';

interface VacancyConversionInfoProps {
    conversion: VacancyAnalyticsConversion;
}

const TrlKeys = {
    viewCount: 'vacancy.stat.title.views',
    responseCount: 'vacancy.stat.title.responses',
    conversion: 'employer.analytics.block.title.conversion',
};

const VacancyConversionInfo: TranslatedComponent<VacancyConversionInfoProps> = ({ conversion, trls }) => {
    const percentValue = NumberFormatter.format(String((conversion?.conversion || 0) * 100), {
        decimalLength: 1,
    });

    return (
        <div className={styles.columns}>
            <ColumnBox title={trls[TrlKeys.viewCount]} value={formatToInt(conversion?.viewCount ?? 0)} />
            <ColumnBox title={trls[TrlKeys.responseCount]} value={formatToInt(conversion?.responseCount ?? 0)} />
            <ColumnBox title={trls[TrlKeys.conversion]} value={`${percentValue}%`} />
        </div>
    );
};

export default translation(VacancyConversionInfo);
