import { Dispatch, FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';
import { AnyAction } from 'redux';

import { CalendarOutlinedSize24, CrossCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Push, usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import { VacancyToggleAction } from 'src/components/Notifications/EmployerVacancies';
import { useNotification } from 'src/components/Notifications/Provider';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { ADD_MODAL, MODAL_AUTO_PROLONG } from 'src/components/VacancyModals/constants';
import translation from 'src/components/translation';
import { Currency } from 'src/models/currencies';
import fetcher from 'src/utils/fetcher';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';
import vacancyAutoProlongation, {
    VacancyAutoProlongationFunc,
} from 'src/components/VacancyActions/vacancyAutoProlongation';

interface RequirementsResponse {
    purchaseRequired: boolean;
    purchaseInfo: {
        vacancyPropertiesPackageTrls: Record<string, string>;
        totalCount: number;
        totalCost: number;
        currency: Currency;
        agreements: never;
    };
}
const REQUIREMENTS_URL = '/shards/employer/vacancies/auto_prolongation/requirements';
declare global {
    interface FetcherPutApi {
        [REQUIREMENTS_URL]: {
            queryParams: void;
            body: {
                vacanciesIds: number[];
            };
            response: RequirementsResponse;
        };
    }
}

interface OpenModalParams {
    dispatchModal: Dispatch<AnyAction>;
    onClose?: VoidFunction;
}
const openModal = ({ dispatchModal, onClose, ...data }: OpenModalParams & { requirements: RequirementsResponse }) => {
    dispatchModal({
        type: ADD_MODAL,
        payload: {
            modalType: MODAL_AUTO_PROLONG,
            data,
        },
    });
};

interface DisableAutoProlongationParams {
    vacanciesIds: number[];
    setLoading: (loading: boolean) => void;
    onClose: VoidFunction;
    vacancyAutoProlongation: VacancyAutoProlongationFunc;
    location: Location;
}
const disableAutoProlongation = async (
    { vacanciesIds, setLoading, onClose, vacancyAutoProlongation, location }: DisableAutoProlongationParams,
    addNotification: AddNotification,
    push: Push
) => {
    setLoading(true);
    try {
        await vacancyAutoProlongation(
            { action: VacancyToggleAction.Disable, vacanciesIds, location },
            addNotification,
            push
        );
    } finally {
        onClose?.();
        setLoading(false);
    }
};

interface ModalParams {
    dispatchModal: Dispatch<AnyAction>;
    vacanciesIds: number[];
    managerIds: number[];
    afterSuccessAction: VoidFunction;
}
interface OpenModalWithRequirementsParams {
    vacanciesIds: number[];
    setLoading: (loading: boolean) => void;
    addNotification: AddNotification;
    onClose: VoidFunction;
    modalParams: ModalParams;
}
const openModalWithRequirements = async ({
    vacanciesIds,
    setLoading,
    addNotification,
    onClose,
    modalParams,
}: OpenModalWithRequirementsParams) => {
    setLoading(true);
    let requirements;
    try {
        requirements = (await fetcher.put(REQUIREMENTS_URL, { vacanciesIds }))?.data;
    } catch (error) {
        defaultRequestErrorHandler(error, addNotification);
        return;
    } finally {
        onClose?.();
        setLoading(false);
    }

    openModal({ ...modalParams, requirements });
};

interface TriggerActionParams {
    onClose: VoidFunction;
    dispatchModal: Dispatch<AnyAction>;
    managerIds: number[];
    isAutoProlongationPlanned: boolean;
    location: Location;
    afterSuccessAction: VoidFunction;
    vacancyAutoProlongation: VacancyAutoProlongationFunc;
}

interface UseTriggerActionParams extends TriggerActionParams {
    vacanciesIds: number[];
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
}

const useTriggerAction = ({
    isAutoProlongationPlanned,
    vacanciesIds,
    managerIds,
    vacancyAutoProlongation,
    onClose,
    dispatchModal,
    isLoading,
    setLoading,
    location,
    afterSuccessAction,
}: UseTriggerActionParams) => {
    const { addNotification } = useNotification();
    const push = usePush();

    return useCallback(async () => {
        if (isLoading) {
            return;
        }

        if (isAutoProlongationPlanned) {
            await disableAutoProlongation(
                {
                    vacanciesIds,
                    setLoading,
                    onClose,
                    vacancyAutoProlongation,
                    location,
                },
                addNotification,
                push
            );
            return;
        }

        const modalParams: ModalParams = { dispatchModal, vacanciesIds, managerIds, afterSuccessAction };

        await openModalWithRequirements({ vacanciesIds, setLoading, addNotification, onClose, modalParams });
    }, [
        isLoading,
        isAutoProlongationPlanned,
        dispatchModal,
        vacanciesIds,
        managerIds,
        afterSuccessAction,
        setLoading,
        addNotification,
        onClose,
        vacancyAutoProlongation,
        location,
        push,
    ]);
};

const TrlKeys = {
    [VacancyToggleAction.Enable]: 'employer.vacancies.autoProlongation.enable',
    [VacancyToggleAction.Disable]: 'employer.vacancies.autoProlongation.disable',
};

const triggerName = 'auto-prolongation-toggle';

interface AutoProlongationActionProps extends BasicTriggerPropsWithoutTriggerName, TriggerActionParams {
    Component: FC<BasicTriggerProps>;
    vacanciesIds: number[];
    permissions: string[];
}

const AutoProlongationAction: TranslatedComponent<AutoProlongationActionProps> = ({
    Component,
    permissions,
    triggerType,
    additionalAnalyticsParams,
    vacanciesIds,
    trls,
    icon,
    ...triggerActionParams
}) => {
    const [isLoading, setLoading] = useState(false);

    const handleTrigger = useTriggerAction({ ...triggerActionParams, isLoading, setLoading, vacanciesIds });
    const action = triggerActionParams.isAutoProlongationPlanned
        ? VacancyToggleAction.Disable
        : VacancyToggleAction.Enable;
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: action === VacancyToggleAction.Enable ? <CalendarOutlinedSize24 /> : <CrossCircleOutlinedSize24 />,
    });

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={handleTrigger}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            isLoading={isLoading}
            additionalAnalyticsParams={{ ...additionalAnalyticsParams, action }}
            batchLength={vacanciesIds.length}
            icon={icon}
            {...iconProps}
        >
            {trls[TrlKeys[action]]}
        </Component>
    );
};

export default connect(
    ({
        permissions,
        router: { location },
    }: {
        permissions: string[];
        router: {
            location: Location;
        };
    }) => ({
        permissions,
        location,
    }),
    {
        vacancyAutoProlongation,
    }
)(translation(AutoProlongationAction));
