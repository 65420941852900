import { useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Text, Card, Link, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { CanvacSimilarVacancy } from 'src/models/canvacSimilarVacancies';
import SimilarVacancy from 'src/pages/VacancyView/components/VacancyStatTab/CompetitorCard/SimilarVacancy';

import styles from './similar-vacancy-list.less';

const TrlKeys = {
    title: 'employer.vacancy.tab.stat.similarVacancies.title',
    showMore: 'employer.vacancy.tab.stat.similarVacancies.showMore',
    noCompensation: 'employer.vacancy.tab.stat.similarVacancies.noCompensation',
};

interface SimilarVacancyListProps {
    vacancies: CanvacSimilarVacancy[];
    vacancyId?: number;
}

const DEFAULT_DISPLAYED_VACANCIES = 6;
const MAX_DISPLAYED_VACANCIES = 12;

const SimilarVacancyList: TranslatedComponent<SimilarVacancyListProps> = ({ vacancies, vacancyId, trls }) => {
    const { isXS } = useBreakpoint();
    const [numDisplayedVacancies, setNumDisplayedVacancies] = useState(DEFAULT_DISPLAYED_VACANCIES);

    const onShowMore = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_similar_vacancies_show_more', { vacancyId });
        setNumDisplayedVacancies(MAX_DISPLAYED_VACANCIES);
    };
    const isVisibleShowMoreBtn = vacancies.length > 0 && numDisplayedVacancies !== MAX_DISPLAYED_VACANCIES;

    const similarVacanciesListRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_similar_vacancies_list',
        vacancyId,
    });

    const showMoreLink = (
        <Link Element="button" onClick={onShowMore} typography="label-2-regular" data-qa="tab-stat-show-more">
            {trls[TrlKeys.showMore]}
        </Link>
    );

    return (
        <Card stretched borderRadius={24} style="neutral" padding={24} ref={similarVacanciesListRef}>
            <div className={styles.similarVacancyListHeading}>
                <Text Element="span" typography="label-2-regular">
                    {trls[TrlKeys.title]}
                </Text>
                {isVisibleShowMoreBtn && !isXS && showMoreLink}
            </div>
            <VSpacing default={24} />
            <div className={styles.similarVacancyList}>
                {vacancies.slice(0, numDisplayedVacancies).map((vacancy, ind) => (
                    <SimilarVacancy
                        vacancy={vacancy}
                        key={vacancy.id}
                        noCompensationText={trls[TrlKeys.noCompensation]}
                        numInList={ind + 1}
                    />
                ))}
            </div>
            {isVisibleShowMoreBtn && isXS && (
                <>
                    <VSpacing default={12} />
                    {showMoreLink}
                </>
            )}
        </Card>
    );
};

export default translation(SimilarVacancyList);
