import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import BannerWrapper from 'src/components/VacancyBannerWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import formatToInt from 'src/utils/formatToInt';

interface SuitableVacanciesBannerProps {
    vacancyId: string;
}

const TrlKeys = {
    title: 'employer.analytics.block.suitableVacancies.title',
    text: 'employer.analytics.block.suitableVacancies.text',
};

const SuitableVacanciesBanner: TranslatedComponent<SuitableVacanciesBannerProps> = ({ vacancyId, trls }) => {
    const link = `${paths.resumeSearch}?vacancy_id=${vacancyId}&order_by=relevance&search_period=365&from=vacancy_view_banner&hhtmFromLabel=vacancy_view_banner`;
    const resumesCount = useSelector((state) => state.resumeSearchResult.totalResults);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (resumesCount && ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'vacancy_suitable_resumes_banner',
                vacancyId,
                isVacancySuitableResumesExp: false,
            });
        }
    });

    if (!resumesCount) {
        return null;
    }

    const similarResumesCount = String(formatToInt(resumesCount));

    const onClickLink = () => {
        Analytics.sendHHEventButtonClick('new_employer_vacancy_promo_banner_suitable_resumes_link', {
            vacancyId,
            isVacancySuitableResumesExp: false,
        });
    };

    return (
        <div ref={ref}>
            <Link to={link} target="_blank" onClick={onClickLink}>
                <BannerWrapper
                    dataQa="suitable-vacancies-banner"
                    header={format(trls[TrlKeys.title], { '{0}': similarResumesCount })}
                >
                    <>{trls[TrlKeys.text]}</>
                </BannerWrapper>
            </Link>
        </div>
    );
};

export default translation(SuitableVacanciesBanner);
