import { FC } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';

const TrlKeys = {
    restore: 'vacancy.tools.repostFromArchive',
};

interface RestoreActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    iconOnly?: boolean;
    vacancyId: number;
}

const RestoreAction: TranslatedComponent<RestoreActionProps> = ({
    Component,
    triggerType,
    trls,
    vacancyId,
    icon,
    iconOnly = false,
}) => (
    <Component
        isPermitted
        triggerName="restore"
        triggerHref={`/employer/vacancy/restore/${vacancyId}`}
        triggerType={triggerType}
        icon={icon}
    >
        {!iconOnly && trls[TrlKeys.restore]}
    </Component>
);

export default translation(RestoreAction);
