import { FC, MutableRefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Skeleton } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { H1Section, H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import RelatedEmployerVacancies from 'src/components/RelatedEmployerVacancies';
import RelatedVacanciesEmployerTitle from 'src/components/RelatedEmployerVacancies/Title';
import RelatedVacancies from 'src/components/RelatedVacancies';
import RelatedVacanciesTitle from 'src/components/RelatedVacancies/Title';
import { RelatedVacanciesType } from 'src/components/RelatedVacancies/relatedVacanciesTypes';
import { useSelector } from 'src/hooks/useSelector';
import { addUserLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import fetcher from 'src/utils/fetcher';

const relatedVacanciesAction = makeSetStoreField('relatedVacancies');
const relatedVacanciesForEmployerAction = makeSetStoreField('relatedVacanciesForEmployer');

interface RelatedVacanciesLazyWrapperProps {
    blockRef?: MutableRefObject<HTMLDivElement>;
    forEmployer?: boolean;
    isEmployerRedesign?: boolean;
}

const RelatedVacanciesLazyWrapper: FC<RelatedVacanciesLazyWrapperProps> = ({
    blockRef,
    forEmployer,
    isEmployerRedesign,
}) => {
    const relatedVacanciesType = useSelector((state) => state.relatedVacanciesType) as RelatedVacanciesType;
    const relatedVacanciesEmployerId = useSelector((state) => state.relatedVacanciesEmployerId);
    const relatedVacancies = useSelector((state) => state.relatedVacancies);
    const relatedVacanciesForEmployer = useSelector((state) => state.relatedVacanciesForEmployer);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const searchSessionId = useSelector((state) => state.searchSessionId);

    useEffect(() => {
        if (forEmployer && !relatedVacanciesEmployerId) {
            return undefined;
        }
        const abortController = new AbortController();
        fetcher
            .get('/shards/vacancy/related_vacancies', {
                params: {
                    vacancyId,
                    page: 0,
                    employerId: forEmployer ? relatedVacanciesEmployerId : null,
                    searchSessionId,
                },
                signal: abortController.signal,
            })
            .then((response) => {
                response.type = relatedVacanciesType;
                const actions = response.vacancies.map(({ vacancyId, userLabels }) =>
                    addUserLabelsForVacancies({ vacancyId, labels: userLabels })
                );
                const setRelatedAction = forEmployer
                    ? relatedVacanciesForEmployerAction(response)
                    : relatedVacanciesAction(response);
                dispatch([...actions, setRelatedAction]);
            })
            .catch((e) => {
                if (fetcher.isCancel(e)) {
                    return;
                }

                console.error(e);
            })

            .finally(() => {
                setIsLoading(false);
            });
        return () => {
            abortController.abort();
        };
    }, [dispatch, forEmployer, relatedVacanciesEmployerId, relatedVacanciesType, searchSessionId, vacancyId]);

    if (forEmployer && !relatedVacanciesEmployerId) {
        return null;
    }

    if (!isLoading) {
        if (forEmployer && !relatedVacanciesForEmployer.resultsFound) {
            return null;
        }
        if (!forEmployer && !relatedVacancies.resultsFound) {
            return null;
        }
    }

    const TitleComponent = forEmployer ? RelatedVacanciesEmployerTitle : RelatedVacanciesTitle;

    return (
        <>
            {isLoading && (
                <>
                    {isEmployerRedesign ? (
                        <H2>
                            <TitleComponent type={relatedVacanciesType} />
                        </H2>
                    ) : (
                        <H1Section Element="h2">
                            <TitleComponent type={relatedVacanciesType} />
                        </H1Section>
                    )}
                    <VSpacing base={isEmployerRedesign ? 6 : 4} />
                    <Skeleton height={300} loading borderRadius={16} />
                    <VSpacing base={4} />
                </>
            )}
            {!isLoading && !forEmployer && (
                <RelatedVacancies
                    initialType={relatedVacanciesType}
                    blockRef={blockRef}
                    isEmployerRedesign={isEmployerRedesign}
                />
            )}
            {!isLoading && forEmployer && <RelatedEmployerVacancies initialType={relatedVacanciesType} />}
        </>
    );
};

export default RelatedVacanciesLazyWrapper;
