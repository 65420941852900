import { VSpacingContainer } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import HiringManagersList from 'src/pages/VacancyView/components/VacancyManagersTab/HiringManagersList';
import OwnerManagerBlock from 'src/pages/VacancyView/components/VacancyManagersTab/OwnerManagerBlock';
import RecruiterManagersList from 'src/pages/VacancyView/components/VacancyManagersTab/RecruiterManagersList';

const VacancyManagersTab: TranslatedComponent = () => (
    <ColumnsRow>
        <Column xs="4" s="8" m="8" l="10">
            <VSpacingContainer default={24}>
                <OwnerManagerBlock />
                <HiringManagersList />
                <RecruiterManagersList />
            </VSpacingContainer>
        </Column>
    </ColumnsRow>
);

export default translation(VacancyManagersTab);
