import { FC } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    applicants: 'applicants',
};

interface ResponsesLinkActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    vacancyId: number;
}

const RESPONSES_LINK = '/employer/vacancyresponses';

const ResponsesLinkAction: TranslatedComponent<ResponsesLinkActionProps> = ({
    Component,
    vacancyId,
    triggerType,
    trls,
}) => {
    const negotiationsCount = useSelector((state) => state.employerNegotiations.count);
    const responsesUrl = updateUrl(RESPONSES_LINK, { vacancyId });
    const triggerName = 'responses-link';

    return (
        <Component
            counter={negotiationsCount ? String(negotiationsCount) : undefined}
            triggerName={triggerName}
            triggerType={triggerType}
            triggerHref={responsesUrl}
        >
            {trls[TrlKeys.applicants]}
        </Component>
    );
};

export default translation(ResponsesLinkAction);
